/**********************************************************************
 *
 * @模块名称: SoftTableDetail
 *
 * @模块用途: SoftTableDetail  模态框配置
 *
 * @创建人: diaojw
 *
 * @date: 2021-11-17 17:19:45
 *
 **********************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import {FormForTableRef, ModalForTableProps, StoreEnum} from "../typing";
import SoftTableFrom from "./SoftTableForm";
import {Form, Input, Button, Col, Drawer, Row} from 'antd';
import ImageComponents from "@pages/alarmMangage/components/imageComponent";
import VideoComponent from "@pages/alarmMangage/components/videoComponent";
import AlarmMakeResult from "@pages/alarmMangage/components/alarmMakeResult";
import {useHistory} from "react-router-dom";
import AlertMap from "@components/AMapMap/AlertMap";
import {
    getPicturesAndVideoByAnnexList,
    downloadAllData,
    downloadImageData,
    downloadVideoData, getFileName
} from "@components/AlertInfo/AlarmAttachment";
import '../css/softTableDetail.scss'

enum Title {
    alarmdetail = '报警详情',
}

const SoftTableDetail: React.FC<ModalForTableProps> = (props) => {
    const {state, dispatch} = props;
    const [formMessage] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isVisibleMessage, setIsVisibleMessage] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const formRef = useRef<FormForTableRef | any>(null);
    const history = useHistory();

    const {pictures, videos} = getPicturesAndVideoByAnnexList(state[StoreEnum.alarmdetail]);

    // 报警信息
    useEffect(() => {
        if (state[StoreEnum.alarmdetail]) {
            setFormData(state[StoreEnum.alarmdetail]);
            setIsModalVisible(true);
        }
    }, [state[StoreEnum.alarmdetail]]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleCancelMessage = () => {
        setIsVisibleMessage(false);
    };
    // 标题
    const titleHandler = () => {
        return (
            <span>
                 <i className='iconfont icon-baojing'></i>&nbsp;
                {Title.alarmdetail}
            </span>
        )
    }
    const titleMessage = () => {
        return (
            <span>
                 <i className='iconfont icon-xiaoxitishi'></i>&nbsp;
                消息提醒
            </span>
        )
    }
    // 消息提醒
    const messageInfoHandler = () => {
        setIsVisibleMessage(true);
    }
    // 消息提交
    const handleOkMessage = () => {
        setIsVisibleMessage(false);
    }
    // 预览
    const gotoVideopaly = () => {
        history.push({
            pathname: '/vehicleMange/videoLive',
            state: {
                carId: formData?.carId,
                deviceType: formData?.deviceType,
            }
        })
    }
    /**
     * 处理过的详情数据
     */
    const formDataAlarmdetail = formData;

    return (
        <React.Fragment>
            <Drawer
                width={formData.type === 'handle' ? 1050 : 720}
                contentWrapperStyle={{paddingTop: 50}}
                title={titleHandler()}
                placement="right"
                destroyOnClose
                visible={isModalVisible}
                onClose={handleCancel}
                footer={false}>
                {formData.type === 'handle' ?
                    <Row gutter={[15, 15]} style={{height: '100%'}}>
                        <Col span={8} style={{height: '100%'}}>
                            <SoftTableFrom ref={formRef} formData={formData}/>
                            <div style={{height: 'calc(100% - 500px)', marginTop: '10px'}}>
                                <div>
                                    <ImageComponents height={200} formData={Object.assign({}, formData, {pictures})}/>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                    <VideoComponent height={200} formData={Object.assign({}, formData, {videos})}/>
                                </div>
                            </div>
                        </Col>
                        <Col span={16} style={{height: '100%'}}>
                            <div className='options-by modal-footer'>
                                <div className='left-btn'>
                                    <Button type='primary' className='byn-list' disabled>语音对讲</Button>
                                    <Button onClick={() => downloadAllData(pictures, videos, getFileName(formDataAlarmdetail))}
                                            disabled={pictures?.length === 0 && videos?.length === 0}
                                            type='primary' className='byn-list'>下载所有</Button>
                                    <Button disabled={true} type='primary' className='byn-list'
                                            onClick={messageInfoHandler}>消息提醒</Button>
                                    <Button onClick={() => downloadImageData(pictures, getFileName(formDataAlarmdetail))}
                                            disabled={pictures?.length === 0}
                                            type='primary' className='byn-list'>下载图片</Button>
                                    <Button onClick={gotoVideopaly} type='primary'
                                            disabled={formData?.deviceType !== 1}
                                            className='byn-list'
                                            style={{width: '88px'}}>预览</Button>
                                    <Button onClick={() => downloadVideoData(videos, getFileName(formDataAlarmdetail))}
                                            disabled={videos?.length === 0}
                                            type='primary' className='byn-list'>下载视频</Button>
                                </div>
                                <div className='right-btn'>
                                    <AlarmMakeResult
                                        handleOk={handleCancel}
                                        formData={formDataAlarmdetail}
                                        state={state}
                                        dispatch={dispatch}/>
                                </div>
                            </div>
                            <div style={{height: 'calc(100% - 215px)', background: '#ddd', marginTop: '10px'}}>
                                <AlertMap locationPosition={formData}/>
                            </div>
                        </Col>
                    </Row> :
                    <AlarmMakeResult handleOk={handleCancel} formData={formDataAlarmdetail}/>
                }
                {/*消息提醒*/}
                <Drawer
                    width={400}
                    contentWrapperStyle={{paddingTop: 50}}
                    title={titleMessage()}
                    placement="right"
                    destroyOnClose
                    visible={isVisibleMessage}
                    onClose={handleCancelMessage}
                    footer={<div className='drawer-footer'>
                        <Button onClick={handleCancel}>取消</Button>&nbsp;&nbsp;&nbsp;
                        <Button type='primary' onClick={handleOkMessage}>确定</Button>
                    </div>}>
                    <Form
                        name="basic"
                        form={formMessage}
                        labelCol={{span: 6}}
                        wrapperCol={{span: 18}}
                        initialValues={{remember: true}}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="消息内容"
                            name="password"
                        >
                            <Input.TextArea showCount maxLength={255} placeholder='请输入消息内容' rows={4}/>
                        </Form.Item>
                    </Form>
                </Drawer>
            </Drawer>
        </React.Fragment>
    );
};

export default SoftTableDetail;
