/**********************************************************************
 *
 * @模块名称: antd
 *
 * @模块作用: antd 组件数据处理
 *
 * @创建人: ligm12
 *
 * @date: 2023/2/3 14:44
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import { isFunction } from "@gaopeng123/utils";

/**
 * 将obj枚举类型转换antd ValueEnum类型
 * @param obj
 * @param callBack
 */
export const obj2ValueEnum = (obj: any, callBack?: (v: any, key: string) => any) => {
    const newObj: any = {};
    for (let key in obj) {
        newObj[key] = isFunction(callBack) ? callBack && callBack(obj[key], key) : {
            text: obj[key]
        }
    }
    return newObj
}

/**
 * 将obj枚举类型转为Options类型
 * @param obj
 * @param callBack
 */
export const obj2Options = (obj: any, callBack?: (key: string, v: any) => any): Array<{ label: string, value: any }> => {
    const opts: any = [];
    for (let key in obj) {
        opts.push(callBack && isFunction(callBack) ? callBack(key, obj[key]) : {
            label: obj[key],
            value: key,
        })
    }
    return opts;
}
/**
 * 将opts list 转为 enum类型数据
 * @param opts
 * @param callBack
 */
export const opts2Enum = (opts: any, callBack?: (item: any, index: number) => any): Array<{ label: string, value: any }> => {
    const optEnum: any = {};
    opts?.forEach((item: any, index: number) => {
        optEnum[item.value] = callBack && isFunction(callBack) ? callBack(item, index) : {
            text: item.label
        }
    })
    return optEnum;
}

/**
 * 处理表格的form表单项
 * @param placeholder
 */
export const tableFormItemProps = (placeholder: string | Array<string>) => {
    return {
        formItemProps: {
            label: '',
        },
        fieldProps: {
            placeholder: placeholder
        },
    };
}

/**
 * 名称正则限制
 */
export const nameRules = [
    {max: 20, min: 2, message: ''},
    {
        pattern: /^(?![0-9])[\u0041-\u005A\u0061-\u007A\u4E00-\u9FA5\u0030-\u0039]+$/,
        message: '由2-20个汉字、大小写字母、阿拉伯数字组成，不能以阿拉伯数字开头'
    }
];

export const idRules = [
    {max: 30, message: '请输入30个以内字符'},
    // {pattern: /^[a-zA-Z]/, message: '必须以英文字母开头'},
    {pattern: /^[a-zA-Z0-9_]+$/, message: '仅能输入英文字母、数字、下划线'}
]

/**
 * 处理分页最后一条删除的场景
 * @param tableRef
 */
export const changePageInfoAfterDel = (pageInfo: any, len?: number) => {
    const delLen = len || 1;
    return (pageInfo.current - delLen) * pageInfo.pageSize >= (pageInfo.total - delLen)
}
export const reloadAfterDel = (tableRef: any, len?: number) => {
    const pageInfo = tableRef.pageInfo;
    if (changePageInfoAfterDel(pageInfo, len)) {
        tableRef?.reloadAndRest();
    } else {
        tableRef?.reload();
    }
}