/**********************************************************************
 *
 * @模块名称: Screen
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/6/1 17:19
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import iconAll from "@/layouts/RightLayout/components/设备管理.svg";
import shippingIcon from "@/layouts/RightLayout/components/拖运大屏.svg";
import {RouteWithModuleRoutes} from "@gaopeng123/hoc";
import CurrentMenus from "@store/Menus";

type ScreenImgProps = {
    path: string,
    icon: string,
    alt: string,
    [propsName: string]: any,
}

const screenDefaultRouter: Array<ScreenImgProps> = [{
    path: '/screen',
    icon: iconAll,
    alt: '星途车联智能'
}, {
    path: '/screen/shipping',
    icon: shippingIcon,
    alt: '智能托运系统'
}];

export const isScreenRouter = (menu: any) => {
    return menu?.menuName === '星途大屏' || menu?.path === '/screen'
}

export const useScreenRouter = () => {
    const menus: any = useRecoilValue(CurrentMenus);
    const [screenRouter, setScreenRouter] = useState<Array<ScreenImgProps>>([]);
    useEffect(() => {
        if (menus?.length) {
            for (const menu of menus) {
                if (menu?.menuName === '星途大屏' || menu?.path === '/screen') {
                    const newScreenRouter = menu?.children?.map((item: any) => {
                        return Object.assign({}, item, screenDefaultRouter.filter((item2) => item2.path === item.path)[0])
                    });
                    setScreenRouter(newScreenRouter);
                    return;
                }
            }
        }
    }, [menus]);

    return screenRouter;
}

const Screen: React.FC<any> = (props: any) => {
    const router = useScreenRouter();
    const onRouteChange = () => {

    }
    return (
        <RouteWithModuleRoutes onRouteChange={onRouteChange} routers={router}/>
    )
}

export default Screen;