/*
@name: VideoComponent
@author: diaojw
@time: 2022-01-07 09:22:47
@description:
*/
import React, {Fragment} from 'react';
import styles from "@pages/alarmMangage/styles.module.less";
import {Carousel} from "antd";

const VideoComponent: React.FC<any> = (props) => {
    const {formData} = props
    return (
        <Carousel effect="fade" dotPosition={'top'} dots={{className: styles.imageComponent}}>
            {
                formData?.videos?.map((item: any) => {
                    return <div className='image-wrap' style={{height: '100%'}}>
                        <div className='image-wrap' style={{height: '100%'}}>
                            <video
                                width='100%'
                                autoPlay
                                height='100%'
                                src={item?.fileUrl}
                            />
                        </div>
                    </div>
                })
            }
        </Carousel>
    )
}
export default VideoComponent;
