/**********************************************************************
 *
 * @模块名称: ChangePassWordModal
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/6/6 14:44
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
 import {Modal} from 'antd';
 import React, {useState, useRef, useEffect} from 'react';
 import ChangePasswordForm from "./ChangePasswordForm";
 import {useRecoilValue} from "recoil";
 import CurrentUser from "@store/CurrentUser";
 import styles from './styles.module.less';
 
 const ChangePassWordModal: React.FC<any> = (props: any) => {
     const [isModalVisible, setIsModalVisible] = useState(false);
 
     const currentUser: any = useRecoilValue(CurrentUser);
 
     const formRef = useRef<any>();
 
     const showModal = () => {
         setIsModalVisible(true);
     };
 
     useEffect(() => {
         // 当密码过期时 需要处理下
         setTimeout(() => {
             if (currentUser?.isChangePassWord && !window.location?.hash?.endsWith('/login')) {
                 showModal();
             }
         }, 3000)
     }, [currentUser]);
 
     const handleOk = () => {
         formRef.current?.changePassword().then((res: any) => {
             setIsModalVisible(false);
         })
     };
 
 
     return (
         <>
             <Modal
                 destroyOnClose={true}
                 width={600}
                 title="密码修改"
                 className={styles.modal}
                 visible={isModalVisible}
                 onOk={handleOk}
                 closeIcon={null}
                 okText={`确认修改`}
                 cancelText={``}
                 cancelButtonProps={{hidden: true}}
             >
                 <ChangePasswordForm ref={formRef}/>
             </Modal>
         </>
     );
 }
 
 export default ChangePassWordModal;
 