/**
 * @function
 * @param
 */
export const menuTreeNodeFn = (array: any) => {
    for (var i in array) {
        array[i].title = array[i].menuName
        array[i].value = array[i].menuId
        if (array[i].children) {
            menuTreeNodeFn(array[i].children)
        }

    }
    return array
}
export const menuTreeNodeList = (array: any) => {
    for (var i in array) {
        array[i].id = array[i].menuId
        array[i].name = array[i].menuName
        if (array[i].scheme && array[i].scheme !== 'null') {
            array[i].component = array[i].scheme
        }
        array[i].icon = array[i].menuIcon
        array[i].exact = true
        array[i].hideInMenu = array[i].menuDescription === '隐藏菜单' ? true :false
        if (array[i].children) {
            menuTreeNodeList(array[i].children)
        }
    }
    return array
}
export const menuTreeNodePersonFn = (array: any) => {
    for (var i in array) {
        array[i].title = array[i].name
        array[i].value = array[i].id
        array[i].disabled = !array[i].auth

        if (array[i].children) {
            menuTreeNodePersonFn(array[i].children)
        }

    }
    return array
}
export const menuFn = (array: any) => {
    for (var i in array) {
        array[i].icon = array[i].menuIcon
        array[i].key = array[i].scheme
        array[i].title = array[i].menuName
        if (array[i].children) {
            menuFn(array[i].children)
        }

    }
    return array
}
export const menuFnm = (array: any) => {
    for (var i in array) {
        array[i].key = array[i].menuId
        array[i].title = array[i].menuName
        if (array[i].children) {
            menuFnm(array[i].children)
        }
    }
    return array
}
export const treeNodeSwitch = (array: any,key:string,name:string,children:string) => {
    let arr = JSON.parse(JSON.stringify(array))
    for (var item of arr) {
        item.key = item[key]
        item.title = item[name]
        if (item[children]) {
            treeNodeSwitch(item[children],key,name,children)
        }
    }
    return arr
}
export const menuTreeKeyFn = (array: any) => {
    for (var i in array) {
        array[i].key = Math.random() * 10000000
        if (array[i].children) {
            menuTreeKeyFn(array[i].children)
        }

    }
    return array
}

export const treeExpanse = (array: any, arrnone: any) => {
    array.forEach((item: any) => {
        let obj: any = {}
        obj.id = item.id
        obj.parentId = item.parentId
        obj.name = item.name
        arrnone.push(obj)
        if (item.children !== undefined) {
            treeExpanse(item.children, arrnone)
        }
    })
    return arrnone
}
export const SetTree = (arr: any) => {
    const setArr = new Set(arr)
    let arrn = Array.from(setArr)
    arrn.forEach((items: any) => {
        if (items.children !== undefined) {
            const setArr = new Set(items.children)
            items.children = Array.from(setArr)
            SetTree(items.children)
        }
    })
    return arrn
}

// 人员信息
export const MapTreeData = (data: any) => {
    return data.map((item: any) => {
        return {
            title: item.name ? item.name : item.deviceName,
            key: item.id,
            companyCode: item.companyCode,
            companyName: item.companyName,
            parkCode: item.parkCode,
            children:
                item.children === null || item.children.length <= 0 ? []
                    : MapTreeData(item.children)
        }
    })
};


/**
 * 将treeData转换成符合组件的数据结构   扁平风格 给TreeSelect组件使用
 * @param treeData     树的数据结构
 * @param newTreeData  返回新的数据结构
 * @param handle       处理数据的接口
 * @param childsKey    子合集的key
 * @param idKey        id的key
 * @param pIdKey       父id的key
 */
export type TreeSelectDataParams = {
    treeData: any[],
    newTreeData?: any[],
    handle?: any,
    childsKey: string,
    idKey: string,
    pIdKey: string,
    valueKey: string,
    nameKey: string
}
export const getTreeSelectData = ({treeData, newTreeData, handle, childsKey, idKey, pIdKey, nameKey, valueKey}: TreeSelectDataParams): any[] => {
    if (!newTreeData) newTreeData = [];
    for (let item of treeData) {
        /**
         * 根据传递的数据 组装数据
         */
        const child = Object.assign({title: item[nameKey], value: item[valueKey], pId: item[pIdKey]}, item);
        newTreeData.push(handle ? handle(child) : child);
        delete child[childsKey];
        if (item[childsKey] && item[childsKey].length) {
            getTreeSelectData({
                treeData: item[childsKey],
                newTreeData,
                handle,
                childsKey,
                idKey,
                pIdKey,
                nameKey,
                valueKey
            });
        }
    }
    return newTreeData;
};

type TransformTreeDataParams = {
    treeData: any[],
    childsKey: string,
    nameKey: string,
    valueKey: string,
    parentsNode?: any,
    concatChilds?: (T: any) => any
}

/**
 * 转换数据格式 符合ant组件要求
 * @param treeData
 * @param childsKey
 * @param nameKey
 * @param valueKey
 */
export const transformTreeData = ({treeData, childsKey, nameKey, valueKey, parentsNode, concatChilds}: TransformTreeDataParams): any => {
    if (treeData?.length) {
        return treeData?.map((node: any) => {
            const currentNode = Object.assign({}, node, {
                title: node[nameKey],
                value: node[valueKey],
                key: node[valueKey]
            });
            const children = transformTreeData({
                parentsNode: currentNode,
                treeData: node[childsKey],
                concatChilds: concatChilds,
                childsKey,
                nameKey,
                valueKey
            });
            return Object.assign({}, currentNode, {
                [childsKey]: concatChilds ? (children ? children.concat(concatChilds(currentNode)) : concatChilds(currentNode)) : children
            });
        })
    }
};
// 选择框处理
export const selectDataLabelValue = (arr:any,itmelab:any,itemval:any) =>{
    let newArr:any = []
    if(arr.length>0){
        arr.forEach((item:any)=>{
            let obj:any  = {}
            obj.label = item[itmelab]
            obj.value = item[itemval]
            newArr.push(obj)
        })
    }
    return newArr
}
// 数组对象去重
export const setArrayObject = (arr:any,id:any) =>{
    const obj:{[key:string]:boolean} = {}
    const data = arr.reduce((item:any,next:any)=>{
        if(!obj[next[id]]){
            item.push(next)
            obj[next[id]] = true
        }
        return item
    },[])
    return data
}
// 数组对象根据值合并
export const filterData = (key:any,arr:any) => {
    let map: any = {},
        dest = [];
    for (var i = 0; i < arr.length; i++) {
        var ai = arr[i];
        if (!map[ai[key]]) {

            dest.push({
                [key]: ai[key],
                data: [ai]
            });
            map[ai[key]] = ai;
        } else {
            for (var j = 0; j < dest.length; j++) {
                var dj = dest[j];
                if (dj[key] == ai[key]) {
                    dj.data.push(ai);
                    break;
                }
            }
        }
    }
    return dest
}
// 工时状态映射
export const isruning = (status:any) =>{
    switch (status) {
        case 1 :
            return '运行'
        case 2 :
            return '怠速'
        case 4 :
            return '行驶'
    }
}
//  取出数组不相同的元素
export const getArrDifference =(arr1:any, arr2:any) => {
    return arr1.concat(arr2).filter((v:any, i:any, arr:any) => {
        return arr.indexOf(v) === arr.lastIndexOf(v);
    });
}
// 工时管理，颜色与状态
export const isColorType = (timeType:string) =>{
    if(timeType === 'workTimeLength'){   // 工作时长颜色
        return '51, 187, 106,'
    } else if(timeType === 'runningTimeLength'){  // 运行时长颜色
        return '99, 123, 247,'
    } else if(timeType === 'idlingTimeLength'){ // 怠速时长颜色
        return '255, 183, 9,'
    } else if(timeType === 'drivingTimeLength'){ // 行驶时长颜色
        return '0, 200, 200,'
    }
}
// 持续时长
export const constformatSeconds = (value:any)  =>{
    var seconds:any = Number(value);// 需要转换的时间秒
    var minutes:any = 0;// 分
    var hours:any = 0;// 小时
    var days:any = 0;// 天
    if(seconds > 0) {
        minutes = Math.round(Number(seconds/60));
        seconds = Number(seconds%60);
        if(minutes > 60) {
            hours = Number(minutes/60);
            minutes = Number(minutes%60);
            if(hours > 24){
                //大于24小时
                days = Number(hours/24);
                hours = Number(hours%24);
            }
        }
    }
    var result = '';
 
    if(minutes > 0) {
        result = ""+parseInt(minutes)+"分"+result;
    }
    if(hours > 0) {
        result = ""+parseInt(hours)+"小时"+result;
    }
    if(days > 0) {
        result = ""+parseInt(days)+"天"+result;
    }
    if (!days && !hours && !minutes && seconds) {
        result = ""+parseInt(seconds)+"秒";
    }
    return result;
}

// 获取组织权限节点数量
export const getOrgLength = (orgList: any, sum: any, orgId: any) => {
        orgList.forEach((val: any) => {
            if (val.auth) {
                sum = sum + 1;
                orgId = val.id
            }
            if (val.children && val.children.length) {
                let result: any = getOrgLength(val.children, sum, orgId)
                sum = sum + result.sum;
                orgId = result.orgId;
            }
        })
        return { sum, orgId }
    }