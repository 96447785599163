/*
@name: ImageComponent
@author: diaojw
@time: 2022-01-07 09:22:47
@description:
*/
import React from 'react';
import {Carousel, Image} from "antd";
import styles from '../styles.module.less';

const ImageComponents: React.FC<any> = (props) => {
    const {formData, height} = props
    return (
        <Carousel effect="fade" dotPosition={'top'} dots={{className: styles.imageComponent}}>
            {
                formData?.pictures?.map((item: any) => {
                    return <div className='image-wrap' style={{height: '100%'}}>
                        <Image
                            width='100%'
                            height={height}
                            src={item?.fileUrl}
                        />
                    </div>
                })
            }
        </Carousel>
    )
}

export default ImageComponents;
