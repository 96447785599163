/**********************************************************************
 *
 * @模块名称: WebSockerManager
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/3/23 20:24
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import {urlJoinParmas} from "@gaopeng123/utils";
import {getToken, getTanant} from "@httpClient/Global";

type fn = (...props: any) => void;
type WebSocketEvent = {
    onOpen?: fn,
    onMessage?: fn,
    onError?: fn,
    onClose?: fn,
}

type Socket = {
    socket: WebSocket,
    events: Array<WebSocketEvent>,
    state: 'open' | 'message' | 'error' | 'close' | 'create' | 'hidden'
};

const WebSocketManager = () => {
    const sockets: any = {};
    const createSocket = (url: any) => {
        let socket: any;
        try {
            // @ts-ignore  兼容http 和 https
            socket = new WebSocket(`ws${window.location.protocol.startsWith('https') ? 's' : ''}://${window.location.host}${url}` + urlJoinParmas({
                authorization: `Bearer ${getToken()}`,
                tenant: `${getTanant()}`,

            }));

            if (socket) {
                socket.addEventListener('open', (e: any) => {
                    console.info(`${url} 打开`, e);
                    if (sockets[url]) {
                        sockets[url].state = 'open';
                        if (sockets[url].waitingToSend) {
                            sockets[url].waitingToSend?.forEach((message: string) => {
                                sockets[url]?.socket?.send(message);
                            });
                            sockets[url].waitingToSend = [];
                        }
                    }
                });

                socket.addEventListener('message', (e: any) => {
                    // console.info(`${url} message`, e);
                    if (sockets[url]) {
                        const events = sockets[url]?.events;
                        try {
                            events?.onMessage && e?.data && events?.onMessage(JSON.parse(e.data));
                            sockets[url].state = 'message';
                        } catch (e) {
                            console.error(e);
                        }
                    }
                });

                socket.addEventListener('error', (e: any) => {
                    console.info(`${url} 错误信息`, e);
                    if (sockets[url]) {
                        sockets[url].state = 'error';
                    }
                });

                socket.addEventListener('close', (e: any) => {
                    console.info('close', e);
                    if (sockets[url]) {
                        sockets[url].state = 'close';
                    }
                });
            }
        } catch (e) {
            console.info('websocket', e);
        }
        return socket;
    }

    /**
     * 创建socket
     * @param url
     * @param events
     */
    const create = (url: string, events: WebSocketEvent) => {
        if (!sockets[url] || (sockets[url]?.state === 'close' || sockets[url]?.state === 'error')) {
            sockets[url] = {
                socket: createSocket(url),
                // @ts-ignore
                events: events,
                state: 'create',
                waitingToSend: []
            }
        } else {
            // @ts-ignore
            sockets[url].events = events;
            sockets[url].state = 'create';
        }
    }

    /**
     * 关闭socket 如果没有事件 则默认终端
     * @param url
     * @param events
     */
    const close = (url: string, events?: WebSocketEvent) => {
        if (sockets[url]) {
            sockets[url].events = null;
            sockets[url].state = 'hidden';
        }
    }

    /**
     * 销毁socket
     * @param url
     */
    const destroy = (url: string) => {
        if (sockets[url]) {
            const {socket} = sockets[url];
            socket?.close();
            sockets[url].events = null;
            sockets[url] = null;
        }
    }

    const destroyAll = () => {
        for (let url in sockets) {
            destroy(url);
        }
    }

    /**
     * 消息发送
     */
    const sendMessage = (url: string, message: string) => {
        if (sockets[url]) {
            const {socket, waitingToSend} = sockets[url];
            try {
                socket?.send(message)
            } catch (e) {
                waitingToSend?.push(message);
            }
        }
    }

    /**
     * 循环检测
     */
    let loopDetectInterval: any;
    const loopDetect = () => {
        clearInterval(loopDetectInterval);
        loopDetectInterval = setInterval(() => {
            for (const url in sockets) {
                if (sockets[url] && sockets[url]?.events) {
                    const socket: Socket = sockets[url];
                    try {
                        const message = JSON.stringify({code: 'heartbeat'})
                        switch (socket.state) {
                            case 'close':
                                socket.socket = createSocket(url);
                                break;
                            case 'error':
                                socket.socket = createSocket(url);
                                break;
                            case "message":
                                socket?.socket?.send(message);
                                break;
                            case "open":
                                socket?.socket?.send(message);
                                break;
                            case "create":
                                socket?.socket?.send(message);
                                break;
                            case "hidden":
                                break;
                            default:
                                break;
                        }
                    } catch (e) {}
                }
            }
        }, 20000); // 25秒检测一次
    }

    /**
     * 开始检测
     */
    loopDetect();

    /**
     * 监听切屏事件，如果屏幕切走 则不在检测
     */
    document.addEventListener('visibilitychange', (v) => {
        if (document.visibilityState === 'visible') {
            loopDetect();
        } else {
            clearInterval(loopDetectInterval);
        }
    })

    return {create, close, destroy, destroyAll, sendMessage}
};


export default WebSocketManager();