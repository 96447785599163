/**********************************************************************
 *
 * @模块名称: assembleAlertDetail
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/2/18 10:19
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import {uuid} from "@gaopeng123/utils";

export const assembleAlertDetail = (alertRow: any, type?: any) => {
    let obj = {};
    if (alertRow.processRecord) {
        // "PR|1234|3|1|||||||||"  数据基于该种形式进行解构
        const processRecord = alertRow.processRecord;
        const alarmChuli = []; // 电话沟通
        let sijiXiangy = ''; // 司机响应
        let chuliResult = ''; // 处理结果
        let remarkS = ''; // 处理备注
        let makerIndex = 0; // 标记位置
        for (let i = 3; i < processRecord.length; i++) {
            if (processRecord[i] === '|') {
                makerIndex++;
            }
            if (makerIndex === 0) {
                alarmChuli.push(processRecord[i]);
            }
            if (makerIndex === 1) {
                if (processRecord[i] !== '|') sijiXiangy += processRecord[i];
            }
            if (makerIndex === 2) {
                if (processRecord[i] !== '|') chuliResult += processRecord[i];
            }
            if (makerIndex >= 3) {
                if (makerIndex === 3 && processRecord[i] === '|') {
                    i++;
                }
                remarkS += processRecord[i] || '';
            }
        }
        obj = {alarmChuli, sijiXiangy, chuliResult, remarkS};
    }
    return Object.assign({type: 'handle', __uuid: uuid()}, alertRow, obj, type)
}

export const toAlarmChuli = (alarmChuli: any) => {
    if (alarmChuli) return String(alarmChuli.join(''));
    return '';
}
export const toAssembleAlertDetail = (text: string) => {
    return text || '';
}

export const getStringProcessRecord = (alarmCheckRadio: any, values: any) => {
    const {alarmChuli, sijiXiangy} = alarmCheckRadio;
    const {chuliResult, remarkS} = values;
    return 'PR|' + toAlarmChuli(alarmChuli) + '|' + toAssembleAlertDetail(sijiXiangy) + '|' + toAssembleAlertDetail(chuliResult) + '|' + toAssembleAlertDetail(remarkS)
}

/**
 *AlertHasHandle
 * @param record
 */
type AlertHandleType = null | 'check' | 'handle';

export enum AlertHandleTypeEnum {
    check = '查看',
    handle = '处理'
}

export const checkAlertHasHandle = (record: any): AlertHandleType => {
    if (record.alarmProcessed == 1) return null;
    // 查看
    if (record.processRecord) return 'check';
    return 'handle';
}

export const assembleAlertDetailStore = (alertRow: any) => {
    const _type = checkAlertHasHandle(alertRow);
    return {alarmdetail: assembleAlertDetail(alertRow, {type: _type})}
}
