import React, {useEffect} from 'react';
import {Popover, Switch, Slider, message} from "antd";
import { SettingFilled } from '@ant-design/icons';
import './AlarmConfig.less'
import {post} from "@/httpClient";

const loadUserAlarmConfig = () => post('/iot-common-device-tenant/api/nebula/user/alarm/getConfig', {body: {}})
const setUserAlarmConfig = (body: any) => post('/iot-common-device-tenant/api/nebula/user/alarm/setConfig', {body})

let timeout: any = undefined

const AlarmConfig: React.FC<any> = (props) => {
    const {alarmConfigState, alarmConfigDispatch} = props
    const {ready, popupEnable, soundEnable, volume} = alarmConfigState
    const onChange = (field: string) => (value: any) => {
        alarmConfigDispatch({
            type: 'set',
            payload: { [field]: value }
        })
        try {
            clearTimeout(timeout)
        } catch (e) {}
        timeout = setTimeout(async () => {
            const data = {
                popupEnable, soundEnable, volume,
                [field]: value
            }
            const res = await setUserAlarmConfig({
                popupEnable: data.popupEnable ? 1 : 0,
                soundEnable: data.soundEnable ? 1 : 0,
                volume: data.volume
            })
            if (res.code !== 200) {
                message.error(res.msg || '用户报警设置失败')
            } 
        }, 3000)
    }

    const loadAlarmConfig = async () => {
        const res = await loadUserAlarmConfig()
        if (res.code !== 200) {
            message.error(res.msg || '获取用户报警设置失败')
            return alarmConfigDispatch({
                type: 'set',
                payload: {ready: true}
            })
        } 
        alarmConfigDispatch({
            type: 'set',
            payload: {
                popupEnable: !!res.data?.popupEnable,
                soundEnable: !!res.data?.soundEnable,
                volume: res.data?.volume,
                ready: true,
            }
        })
    }

    useEffect(() => {
        loadAlarmConfig()
    }, [])

    if (!ready) return null
    return <Popover
        className='component-alarm-config'
        trigger={['click']}
        overlayClassName='component-alarm-config-overlay'
        content={(
            <>
                <div className="row">
                    <div className="left">报警弹窗</div>
                    <div className="right">
                        <Switch size='small' checked={popupEnable} onChange={onChange('popupEnable')}/>
                    </div>
                </div>
                <div className="row">
                    <div className="left">报警声音</div>
                    <div className="right">
                        <Switch size='small' checked={soundEnable} onChange={onChange('soundEnable')}/>
                    </div>
                </div>
                <div className="row">
                    <div className="left">音量</div>
                    <div className="right">
                        <Slider disabled={!soundEnable} value={volume} onChange={onChange('volume')}/>
                    </div>
                </div>
            </>
        )}
    >
        <SettingFilled className='component-alarm-config-icon'/>
    </Popover>
}

export default AlarmConfig
