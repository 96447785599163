/**********************************************************************
 *
 * @模块名称: typing
 *
 * @模块用途: SoftTable 类型系统
 *
 * @创建人: diaojw
 *
 * @date: 2021-11-17 17:19:45
 *
 **********************************************************************/

/**
 * store类型系统定义
 */
export enum StoreEnum {
    add = 'add',
    del = 'del',
    edit = 'edit',
    refresh = 'refresh',
    detail = 'detail',
    selectedTerminal = 'selectedTerminal',
    currentSelected = 'currentSelected',
    resultStatus = 'resultStatus',
    fuelCalibration = 'fuelCalibration',
}

export type Action = {
    value: any;
    type: StoreEnum
}

/**
 * StoreProps参数
 */
export type Fn = (...args: any) => any;
export type Props = {
    state: any;
    dispatch: Fn;
    [propName: string]: any;
}


export type TableProps = Props & {};
export type ModalForTableProps = Props & {};

export type FormForTableRef = {
    values: () => Promise<any>;
    [propName: string]: any;
}

export type FormForTableProps = {
    formData: any;
    ref?: any
};

export const valueEnum = {
    0: 'close',
    1: 'running',
    2: 'online',
    3: 'error',
};
export type SoftTableBase = {
    [propName: string]: any;
}

export type AddSoftTableProps = {
    [propName: string]: any;
} & SoftTableBase;

export type EditSoftTableProps = {
    [propName: string]: any;
} & SoftTableBase;

export type TableListItem = {
    id?: number;
    key: number;
    name: string;
    containers: number;
    creator: string;
    status: string;
    createdAt: number;
    progress: number;
    money: number;
    memo: string;
};


export const modelCalibrationStatus = {
    0: '未标定',
    1: '已标定'
}

export type VehicleTableListItem = {
    id?: number;
    key: number;
    name: string;
    plateNo: string;
    masterModel: {
        modelStatus: 0 | 1,
        modelTime: string,
    },
    followerModel: {
        modelStatus: 0 | 1,
        modelTime: string,
    };
};