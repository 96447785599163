import {post, get, put} from '@/httpClient';

export interface UserLoginParams {
    username: string,
    password: string,
}

export interface UserInfo {
    token?: string, // token信心 鉴权使用
    username?: string, // 姓名
    menus?: any[], // 菜单信息
    message?: string,
    code?: number
}

// 退出登录
export function loginOut(): Promise<UserInfo> {
	return post('/iot-common-auth-tenant/api/nebula/auth/token/v1/logout');
}

// 获取短信验证码
export const phoneCode = (telNumber: string) => {
    return get(`/iot-common-auth-tenant/api/nebula/auth/token/v1/phoneCode/${telNumber}`)
}

// 账号登录
export const accountLogin = (opts: any) => {
    return post('/iot-common-auth-tenant/api/nebula/auth/token/v1/login', opts)
}

// 短信登录
export const loginByCode = (opts: any) => {
    // telNumber phoneCode
    return post(`/iot-common-auth-tenant/api/nebula/auth/token/v1/loginByCode`, opts)
}

// 重置密码
type ResetPasswordProps = {
    telNumber: string;
    phoneCode: string;
    password: string; // 目前不需要加密
}
export const resetPassword = (params: ResetPasswordProps) => {
    // telNumber phoneCode password
    return put(`/iot-common-auth-tenant/api/nebula/auth/admin/v1/user/password/resetByCode`, {body: params});
}

export const getCaptcha = async (opts: any) => {
    return await get('/iot-common-auth-tenant/api/nebula/auth/token/v1/captcha', opts);
}