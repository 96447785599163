/**********************************************************************
 *
 * @模块名称: device_icon
 *
 * @模块作用: 地址确认
 *
 * @创建人: ligm12
 *
 * @date: 2021/12/15 10:32
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import device1 from "./assets/icon/1-挖掘机.svg";
import device2 from "./assets/icon/2-轮式挖掘机.svg";
import device3 from './assets/icon/8-汽车起重机.svg';
import device4 from './assets/icon/9-全地面起重机.svg';
import device5 from './assets/icon/6-搅拌车.svg';
import device6 from './assets/icon/5-泵车.svg';
import device7 from './assets/icon/3-装载机.svg';
import device8 from './assets/icon/4-自卸车.svg';
import device9 from './assets/icon/15-旋挖钻机.svg';
import device10 from './assets/icon/19-叉车.svg';
import device11 from './assets/icon/24-刚性矿卡.svg';
import device12 from './assets/icon/26-油罐车.svg';
import device13 from './assets/icon/28-智能拖运车.svg';
import device14 from './assets/icon/25-迷你挖.svg';
import device15 from './assets/icon/1-挖掘机.svg';
import device16 from './assets/icon/1-挖掘机.svg';
import device17 from './assets/icon/7-车载泵.svg';
import device18 from './assets/icon/10-桁架臂履带起重机.svg';
import device19 from './assets/icon/27-履带起重机.svg';
import device20 from './assets/icon/10-桁架臂履带起重机.svg';
import device21 from './assets/icon/11-随车起重机.svg';
import device22 from './assets/icon/12-摊铺机.svg';
import device23 from './assets/icon/13-压路机.svg';
import device24 from './assets/icon/14-平地机.svg';
import device25 from './assets/icon/14-平地机.svg';
import device26 from './assets/icon/16-连续墙抓斗.svg';
import device27 from './assets/icon/16-连续墙抓斗.svg';
import device28 from './assets/icon/15-旋挖钻机.svg';
import device29 from './assets/icon/15-旋挖钻机.svg';
import device30 from './assets/icon/17-正面吊.svg';
import device31 from './assets/icon/18-集装箱空箱堆高机.svg';
import device32 from './assets/icon/20-抓钢（料）机.svg';
import device33 from './assets/icon/19-叉车.svg';
import device34 from './assets/icon/21-半挂牵引车.svg';
import device35 from './assets/icon/21-半挂牵引车.svg';
import device36 from './assets/icon/22-机械压裂.svg';
import device37 from './assets/icon/22-机械压裂.svg';
import device38 from './assets/icon/23-消防车.svg';
import device39 from './assets/icon/23-消防车.svg';
import device40 from './assets/icon/23-消防车.svg';
import device41 from './assets/icon/23-消防车.svg';
import device42 from './assets/icon/23-消防车.svg';
/**
 * 大图
 */
import device1_big from "./assets/big/1-挖掘机.png";
import device2_big from "./assets/big/2-轮式挖掘机.png";
import device3_big from './assets/big/8-汽车起重机.png';
import device4_big from './assets/big/9-全地面起重机.png';
import device5_big from './assets/big/6-搅拌车.png';
import device6_big from './assets/big/5-泵车.png';
import device7_big from './assets/big/3-装载机.png';
import device8_big from './assets/big/4-自卸车.png';
import device9_big from './assets/big/15-旋挖钻机.png';
import device10_big from './assets/big/19-叉车.png';
import device11_big from './assets/big/24-刚性矿卡.png';
import device12_big from './assets/big/26-油罐车.png';
import device13_big from './assets/big/28-智能托运车.png';
import device14_big from './assets/big/25-迷你挖.png';
import device15_big from './assets/big/1-挖掘机.png';
import device16_big from './assets/big/1-挖掘机.png';
import device17_big from './assets/big/7-车载泵.png';
import device18_big from './assets/big/10-桁架臂履带起重机.png';
import device19_big from './assets/big/27-履带起重机.png';
import device20_big from './assets/big/10-桁架臂履带起重机.png';
import device21_big from './assets/big/11-随车起重机.png';
import device22_big from './assets/big/12-摊铺机.png';
import device23_big from './assets/big/13-压路机.png';
import device24_big from './assets/big/14-平地机.png';
import device25_big from './assets/big/14-平地机.png';
import device26_big from './assets/big/16-连续墙抓斗.png';
import device27_big from './assets/big/16-连续墙抓斗.png';
import device28_big from './assets/big/15-旋挖钻机.png';
import device29_big from './assets/big/15-旋挖钻机.png';
import device30_big from './assets/big/17-正面吊.png';
import device31_big from './assets/big/18-集装箱空箱堆高机.png';
import device32_big from './assets/big/20-抓钢（料）机.png';
import device33_big from './assets/big/19-叉车.png';
import device34_big from './assets/big/21-半挂牵引车.png';
import device35_big from './assets/big/21-半挂牵引车.png';
import device36_big from './assets/big/22-机械压裂.png';
import device37_big from './assets/big/22-机械压裂.png';
import device38_big from './assets/big/23-消防车.png';
import device39_big from './assets/big/23-消防车.png';
import device40_big from './assets/big/23-消防车.png';
import device41_big from './assets/big/23-消防车.png';
import device42_big from './assets/big/23-消防车.png';

/**
 * 俯视图
 */
import device1_map from "./assets/map/1-挖掘机(64x64).svg";
import device2_map from "./assets/map/2-轮式挖掘机(64x64).svg";
import device3_map from './assets/map/8-汽车起重机(64x64).svg';
import device4_map from './assets/map/9-全地面起重机(64x64).svg';
import device5_map from './assets/map/6-搅拌车(64x64).svg';
import device6_map from './assets/map/5-泵车(64x64).svg';
import device7_map from './assets/map/3-装载机(64x64).svg';
import device8_map from './assets/map/4-自卸车(64x64).svg';
import device9_map from './assets/map/15-旋挖钻机(64x64).svg';
import device10_map from './assets/map/19-叉车(64x64).svg';
import device11_map from './assets/map/24-刚性矿卡(64x64).svg';
import device12_map from './assets/map/26-油罐车(64x64).svg';
import device13_map from './assets/map/28-智能拖运车(64x64).svg';
import device14_map from './assets/map/25-迷你挖(64x64).svg';
import device15_map from './assets/map/1-挖掘机(64x64).svg';
import device16_map from './assets/map/1-挖掘机(64x64).svg';
import device17_map from './assets/map/7-车载泵(64x64).svg';
import device18_map from './assets/map/10-桁架臂履带起重机(64x64).svg';
import device19_map from './assets/map/27-履带起重机(64x64).svg';
import device20_map from './assets/map/10-桁架臂履带起重机(64x64).svg';
import device21_map from './assets/map/11-随车起重机(64x64).svg';
import device22_map from './assets/map/12-摊铺机(64x64).svg';
import device23_map from './assets/map/13-压路机(64x64).svg';
import device24_map from './assets/map/14-平地机(64x64).svg';
import device25_map from './assets/map/14-平地机(64x64).svg';
import device26_map from './assets/map/16-连续墙抓斗(64x64).svg';
import device27_map from './assets/map/16-连续墙抓斗(64x64).svg';
import device28_map from './assets/map/15-旋挖钻机(64x64).svg';
import device29_map from './assets/map/15-旋挖钻机(64x64).svg';
import device30_map from './assets/map/17-正面吊(64x64).svg';
import device31_map from './assets/map/18-集装箱空箱堆高机(64x64).svg';
import device32_map from './assets/map/20-抓钢（料）机(64x64).svg';
import device33_map from './assets/map/19-叉车(64x64).svg';
import device34_map from './assets/map/21-半挂牵引车(64x64).svg';
import device35_map from './assets/map/21-半挂牵引车(64x64).svg';
import device36_map from './assets/map/22-机械压裂(64x64).svg';
import device37_map from './assets/map/22-机械压裂(64x64).svg';
import device38_map from './assets/map/23-消防车(64x64).svg';
import device39_map from './assets/map/23-消防车(64x64).svg';
import device40_map from './assets/map/23-消防车(64x64).svg';
import device41_map from './assets/map/23-消防车(64x64).svg';
import device42_map from './assets/map/23-消防车(64x64).svg';

import DefaultCar from './assets/map/汽车2.svg';
import TitleCar from './assets/工程车-icon.svg';
import { isObject } from "@gaopeng123/utils";

export const DEVICE_ICON = (code: string) => {
    return DEVICE_ICON_BASE(code, 'icon');
}

export const DEFAULT_ICON: any = DefaultCar;
export const DEFAULT_TITLE_CAR: any = TitleCar;
/**
 * 地图上的点坐标 车的icon
 */
export const DEVICE_MAP_ICON = (code: string) => {
    return DEVICE_ICON_BASE(code, 'map');
}

export const DEVICE_ICON_BIG = (code: string) => {
    return DEVICE_ICON_BASE(code, 'big');
}
/**
 * 离线车辆
 * @param code
 * @constructor
 */
export const DEVICE_ICON_BIG_OFFLINE = (code: string) => {
    return DEVICE_ICON_BASE(code, 'offline');
}

type DEVICE_ICON_TYPE_VALUE = 'map' | 'icon' | 'big' | 'offline'

export const DEVICE_ICON_TYPE = {
    map: {
        format: 'svg',
        folder: 'topView64x64'
    },
    icon: {
        format: 'svg',
        folder: 'icon16x16'
    },
    big: {
        format: 'png',
        folder: 'originalImage240x240'
    },
    offline: {
        format: 'png',
        folder: 'originalImageOffline240x240'
    },
}

/**
 * 获取指定的cos地址
 * @param code
 * @param type
 * @constructor
 */
export const DEVICE_ICON_BASE = (code: string, type: DEVICE_ICON_TYPE_VALUE) => {
    const currentType = DEVICE_ICON_TYPE[type];
    return `${window.origin}${process.env.REACT_APP_SERVICE_OSS}/${currentType.folder}/${code}.${currentType.format}`;
}
// * 智能作业终端：
// 1、运行：#52C41A
// 2、怠速：#FFAA00
// 3、静止：#3370FF
// 4：离线：#666666

// 蓝色：#3370FF
// 绿色：#52C41A
// 红色：#E50012
// 黄色：#FF9E00

// 围栏告警
// 一级：#E50012
// 二级：#FF6000
// 三级：#FFAA00

// AiBox：1行驶；2停止；3离线；
/**
 * 智能终端运行状态枚举
 */
export const DEVICE_STATUS_COLOR: any = {
    1: '#52C41A',
    2: '#FF9E00',
    3: '#3370FF',
    4: '#E50012'
}

/**
 * 智能作乐终端枚举
 */
export const DEVICE_STATUS_STATUS: any = {
    1: '运行',
    2: '怠速',
    3: '静止',
    4: '离线'
}

/**
 * ai box 状态
 */
export const AIBOX_STATUS_STATE: any = {
    1: '行驶',
    2: '停止',
    3: '离线',
}

// 车辆状态
export const CAR_STATUS: any = {
    1: '运行',
    2: '怠速',
    3: '静止',
    4: '行驶',
    6: '离线'
}

//车辆状态颜色 
export const CAR_STATUS_COLOR: any = {
    1: '#49AA19',
    2: '#D87A16',
    3: '#0080FF',
    4: '#13A8A8',
    6: '#F03030'
}

/**
 * AIBOX和智能作业终端状态
 */
export const AIBOX_STATUS_AND_DEVICE: any = Object.assign({}, DEVICE_STATUS_STATUS, {4: '行驶', 5: '停止', 6: '离线'})

/**
 * 地图轨迹颜色
 */
export const DEVICE_MAPP_COLOR: any = {
    pass: DEVICE_STATUS_COLOR['1'],
    notPass: DEVICE_STATUS_COLOR['3'],
}

/**
 * AIBOX颜色枚举
 */
export const AIBOX_STATUS_COLOR: any = {
    1: DEVICE_STATUS_COLOR['1'],
    2: DEVICE_STATUS_COLOR['3'],
    3: DEVICE_STATUS_COLOR['4'],
}
/**
 * 告警枚举
 */
export const ALERT_ICON: any = {
    1: 'icon-alarm-1-copy',
    2: 'icon-alarm-2-copy',
    3: 'icon-alarm-full-copy',
}
// 智能托运相关枚举定义
// 车辆在线状态
export const TOWAGE_ONLINE_STATUS: any = {
    0: '离线',
    1: '在线',
}

export const TOWAGE_ONLINE_STATUS_COLOR: any = {
    0: DEVICE_STATUS_COLOR[4],
    1: DEVICE_STATUS_COLOR[1],
}
// 车辆行驶状态
export const TOWAGE_DRIVING_STATUS: any = {
    0: '停车',
    1: '行驶',
}
export const TOWAGE_DRIVING_STATUS_COLOR: any = {
    0: DEVICE_STATUS_COLOR[4],
    1: DEVICE_STATUS_COLOR[3],
}
// 健康状态(1正常/0异常)
export const TOWAGE_HEALTH_STATUS: any = {
    0: '异常',
    1: '正常',
}
export const TOWAGE_HEALTH_STATUS_COLOR: any = {
    0: DEVICE_STATUS_COLOR[4],
    1: DEVICE_STATUS_COLOR[1],
}
// operatingMode
export const TOWAGE_OPERATINGMODE: any = {
    0: '手动模式',
    1: '工作模式',
}

// 车辆监控状态
// export const TOWAGE_ONLINE_DRIVING: any = {
//     0: '停车',
//     1: '行驶',
// }
// export const TOWAGE_ONLINE_DRIVING_COLOR: any = {
//     0: DEVICE_STATUS_COLOR[4],
//     1: DEVICE_STATUS_COLOR[1],
// }


/**
 * 终端告警枚举
 */
export const DEVICE_ALERT_ICON: any = {
    1: 'icon-zhongduanbangding-icon-1',
    2: 'icon-zhongduanbangding-icon-2-copy',
    3: 'icon-zhongduanbangding-icon-3-copy',
}
/**
 * 电子围栏告警
 */
export const FENCE_ALERT_ICON: any = {
    1: 'icon-fence-full-1-copy',
    2: 'icon-fence-full-2-copy',
    3: 'icon-fence-full-3-copy',
}
/**
 * 设备类型枚举
 */
export const DEVICE_TYPE_ENUM: any = {
    smartJobTerminal: "智能作业终端",
    aiboxTerminal: "车载智能终端",
    txbdHttpTerminal: "天星北斗终端",
}

/**
 * 是否是智能作业终端
 */
export const isSmartJobTerminal = (rows: any, isBindDevices = true) => {
    if (!isBindDevices) {
        return rows?.deviceTypeName === "smartJobTerminal";
    }
    if (rows?.bindDevices && rows?.bindDevices?.length) {
        return rows?.bindDevices[0].deviceTypeName === "smartJobTerminal";
    }
    return false
}
/**
 * 判断是否是aibox
 */
export const isAiboxTerminal = (rows: any) => {
    return isObject(rows?.deviceTypeName) ? rows?.deviceTypeName.key === 'aiboxTerminal' : rows?.deviceTypeName === "aiboxTerminal";
}

