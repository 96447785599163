/**********************************************************************
 *
 * @模块名称: api
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/2/17 16:06
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import {checkStatue, post} from "@/httpClient";
import {dealWithParams} from "@/share/utils";

export const realTimeAlertList = async (params: any) => {
    const res = await post(`/iot-xingtu20-service-tenant/alarm/list`, {
        body: dealWithParams(params)
    });
    if (checkStatue(res)) return res?.data || {list: res?.data, total: 0};
    // message.warn(checkMsg(res));
    return {
        list: [],
        total: 0
    };
}