/*
@name: AlarmMakeResult
@author: diaojw
@time: 2022-01-07 09:22:47
@description:
*/
import React, {useEffect, useState} from 'react';
import {Radio, Select, Button, Checkbox, Form, Input, message} from "antd";
import {StoreEnum} from "@pages/alarmMangage/typing";
import {reqAlarmProcess} from "@pages/alarmMangage/api";
import {uuid} from "@gaopeng123/utils";
import {getStringProcessRecord} from "@share/assembleAlertDetail";
import '../css/softTableDetail.scss'
import {checkMsg} from "@/httpClient";

const {Option} = Select

const AlarmMakeResult: React.FC<any> = (props) => {
    const {formData, dispatch, handleOk} = props
    const [alarmCheckRadio, setAlarmCheckRadio] = useState<any>({});
    const [form] = Form.useForm();

    const options = [
        {label: '电话沟通', value: '1'},
        {label: '语音对讲', value: '2'},
        {label: '消息提醒', value: '3'},
        {label: '远程查看', value: '4'},
    ];
    // 刷新处理
    const refreshHandler = () => {
        dispatch({
            type: StoreEnum.refresh,
            value: uuid()
        });
        handleOk && handleOk();
    }
    useEffect(() => {
        if (formData.alarmChuli != undefined) {
            const {alarmChuli, sijiXiangy, chuliResult, remarkS} = formData;
            setAlarmCheckRadio({alarmChuli, sijiXiangy});
            form.setFieldsValue({chuliResult, remarkS});
        }
    }, [formData]);

    const onChangeCheckbox = (val: any) => {
        setAlarmCheckRadio({...alarmCheckRadio, alarmChuli: val})
    }
    const onChangeRadio = (val: any) => {
        setAlarmCheckRadio({...alarmCheckRadio, sijiXiangy: val.target.value})
    }
    const onFinish = (values: any) => {
        const processRecord = getStringProcessRecord(alarmCheckRadio, values);
        alarmMakeResultHandler(processRecord);
    };

    const onFinishFailed = (errorInfo: any) => {
    };
    // 报警处理接口函数
    const alarmMakeResultHandler = async (processRecord: any) => {
        const {id, deviceType} = formData
        const obj = {
            alarmId: id,
            deviceType,// 1AIBox；2智能作业终端
            processRecord: encodeURI(processRecord), // PR报警处理开头标志，报警处理char[0]，司机响应char[1]，处理结果char[2]，处理备注剩下的
        }
        const res = await reqAlarmProcess(obj)
        if (res.code === 200) {
            message.success(checkMsg(res));
        } else {
            message.warn(checkMsg(res))
        }
        refreshHandler();
    }
    /**
     * 是否不可编辑
     */
    const disabled = formData.type !== 'handle';

    return (
        <div className='data-makein'>
            <div className='alarm-mangage-checkbox'>
                <span className='titles'>报警处理：</span>
                <Checkbox.Group disabled={disabled} options={options} onChange={onChangeCheckbox}
                                value={alarmCheckRadio.alarmChuli}
                                style={{width: '100%'}}/>
            </div>
            <div className='alarm-mangage-radio'>
                <span className='titles'>司机响应：</span>
                <Radio.Group disabled={disabled} onChange={onChangeRadio} value={alarmCheckRadio.sijiXiangy}>
                    <Radio value={'1'}>接受提醒</Radio>
                    <Radio value={'2'}>忽略提醒</Radio>
                    <Radio value={'3'}>反对提醒</Radio>
                </Radio.Group>
            </div>
            <div className='alarm-mangage-info'>
                <Form
                    name="basic"
                    form={form}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="处理结果"
                        name="chuliResult"
                        rules={[{required: true, message: '请选择处理结果!'}]}
                    >
                        <Select disabled={disabled} placeholder='请选择处理结果' value={formData.chuliResult}>
                            <Option key={3} value={'3'}>误报</Option>
                            <Option key={1} value={'1'}>已处理完毕</Option>
                            <Option key={2} value={'2'}>不作处理</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="处理备注"
                        name="remarkS"
                    >
                        <Input.TextArea disabled={disabled} showCount maxLength={255} placeholder='请输入处理备注内容' rows={4}/>
                    </Form.Item>
                    {
                        !disabled
                            ? <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                <Button type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Form.Item>
                            : null
                    }
                </Form>
            </div>
        </div>
    )
}

export default AlarmMakeResult;
