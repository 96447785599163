/**********************************************************************
 *
 * @模块名称: utils
 *
 * @模块用途: utils
 *
 * @date: 2021/10/25 17:21
 *
 * @版权所有: pgli
 *
 **********************************************************************/
import { makeParamsProper, isFunction } from '@gaopeng123/utils';
import { isObject } from "@gaopeng123/utils";
import { isSmartJobTerminal } from "@share/deviceIcon";
import { carInfo as deviceCarInfo } from "@pages/vehicleMange/JobMonitoring/api";
import { carInfo as aiboxCarInfo } from "@pages/vehicleMange/RealTimeMonitoring/api";
import { getQueryDeviceList } from "@pages/operationSerive/aibox/api";
import { checkMsg, checkStatue } from "@/httpClient";
import { message, Modal } from "antd";

export const openUrl = (url: string) => {
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

export const random = {
    key: (num = -1): string => `${Math.random()}`.slice(2, num),
    pick: (arr: any[]) => arr[Math.floor(Math.random() * arr.length)],
    multiple: (arr: any[]) => {
        const times = Math.floor(Math.random() * arr.length);
        const res = [];
        for (let i = 0; i < times; i += 1) {
            res.push(random.pick(arr))
        }
        return res
    }
};

/**
 * 处理表格参数
 * @param params
 * @param pageOptions
 */
export const dealWithParams = (params: any, pageOptions?: any) => {
    const {pageNumKey, pageSizeKey} = Object.assign({pageNumKey: 'pageNum', pageSizeKey: 'pageSize'}, pageOptions);
    const newParams = makeParamsProper(params);
    newParams[pageNumKey] = newParams.current;
    newParams[pageSizeKey] = newParams.pageSize;
    // 删除冗余参数
    if (pageNumKey !== 'current') {
        delete newParams.current;
    }
    if (pageSizeKey !== 'pageSize') {
        delete newParams.pageSize;
    }
    return newParams;
};
/**
 * 处理分页最后一条删除的场景
 * @param tableRef
 */
export { changePageInfoAfterDel, reloadAfterDel } from "./antd"
/**
 * 获取车辆的carId
 * @param rows
 */
export const getCarIdByRows = (rows: any, key?: string) => {
    return `${rows.deviceType}_${key ? rows[key] : rows.deviceCode}`
}

/**
 * 获取车辆信息
 */
export const getCarDetailApi = (rows: any, isBindDevices = true) => {
    let fn;
    if (isSmartJobTerminal(rows, isBindDevices)) {
        fn = deviceCarInfo;
    } else {
        fn = aiboxCarInfo
    }
    return fn;
}
/**
 * 根据carId或者车辆的type和code
 * @param carId
 */
export const getCarTypeAndCodeByCarId = (carId: string) => {
    if (carId) {
        const [deviceType, deviceCode] = carId?.split('_');
        return {
            deviceType,
            deviceCode,
        }
    }
    return {
        deviceType: undefined,
        deviceCode: undefined,
    }
}
/**
 * 获取终端信息数据
 * @param binds
 */
export const getBindDevicesData = async (bind: any) => {
    const res = await getQueryDeviceList({id: bind.id}, 1, 100);
    if (checkStatue(res)) {
        return res?.data?.content ? res?.data?.content[0] : {};
    }
    message.warn(checkMsg(res));
    return {}
}

/**
 *
 * 给监控传值 轨迹 视频等模块
 * @param rows
 */
export const dealWithCarMonitorProps = (rows: any, render?: any) => {
    return isFunction(render) ? render(rows) : {
        carId: getCarIdByRows(rows)
    }
}


export const assignIf = (target: any, source: any): any => {
    const _target = Object.assign({}, target);
    if (isObject(_target) && isObject(source)) {
        let property;
        for (property in source) {
            if (_target[property] === undefined || _target[property] === null) {
                _target[property] = source[property];
            }
        }
    }
    return _target;
};

/**
 * 修改http的path路径 如果是生产环境则使用https的环境
 * @param path
 */
export const changeHttpPath = (url: string) => {
    return url ? `${window.origin}/${url?.split('/').slice(3,).join('/')}` : '';
}

/**
 * 处理表格的form表单项
 * @param placeholder
 */
export { tableFormItemProps } from "./antd";

export const modalConfirm = (text: string) => new Promise((resolve) => Modal.confirm({
	title: text,
	onOk: () => resolve(true),
	onCancel: () => resolve(false)
}))

