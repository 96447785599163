/**********************************************************************
 *
 * @模块名称: HocRouter
 *
 * @模块用途: HocRouter 路由缓存处理
 *
 * @date: 2024/3/19 16:43
 *
 * @版权所有: pgli
 *
 **********************************************************************/
import { atom, selector } from 'recoil';

const HocState: any = atom({
    key: 'HocState',
    default: [],
});

const HocRouter = selector<Array<string>>({
    key: 'HocRouter',
    get: ({ get }) => {
        return get(HocState);
    },
    set: ({ set }, newValue) => {
        // 拦截输入 将用户信息写入到Storage
        set(HocState, newValue);
    },
});
export default HocRouter;