/**********************************************************************
 *
 * @模块名称: AlarmAttachment
 *
 * @模块作用: AlarmAttachment 告警附件
 *
 * @创建人: ligm12
 *
 * @date: 2022/1/24 15:20
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import React, {Fragment, useState} from 'react';
import {download, formatTimestamp, uuid} from "@gaopeng123/utils";
import styles from "./styles.module.less";
import imgIcon from './assets/图片.svg';
import videoIcon from './assets/视频.svg';
import SoftTableModal from "@pages/alarmMangage/components/SoftTableModal";
import { post } from "@/httpClient";

type AlarmAttachmentProps = {
    pictures: string;
    videos: string;
    [propsName: string]: any // 其他参数
}

export const getPicturesAndVideoByAnnexList = (formData: any) => {
    const pictures = formData?.annexList?.filter((item: any) => {
        return item?.fileType === 0 || item?.fileType === 1;
    }) || [];

    const videos = formData?.annexList?.filter((item: any) => {
        return item?.fileType === 2 || item?.fileType === 3;
    }) || [];

    return {pictures, videos}
}
/**
 * 下载所有
 * @param fileName
 * @param files
 */
export const downloadImageAndVideo = (fileName: string, files: Array<string>) => {
    if (files?.length) {
        post(`/iot-xingtu20-service-tenant/become/to/stream`, {
            noModification: true,
            body: {"streamUrls": files}
        }).then((res: any) => {
            if (res.status >= 200 && res.status < 300) {
                res.clone().blob().then((blob: Blob) => {
                    download({
                        blob: blob,
                        fileName: `${fileName}_${formatTimestamp(Date.now(), 'yyyyMMddHHmmss')}.zip`
                    });
                });
            }
        });
    }
}
export const getFileName = (data: any): string => {
    return `${data?.licPlateNum}_${data?.alarmMessage}`;
}
// 下载图片
export const downloadImageData = (pictures: any[], fileName?: string) => {
    downloadImageAndVideo(fileName || '告警图片', pictures?.map((picture) => picture?.fileUrl));
}
// 下载视频
export const downloadVideoData = (videos: any[], fileName?: string) => {
    downloadImageAndVideo(fileName || '告警视频', videos?.map((video) => video?.fileUrl));
}
// 下载所有
export const downloadAllData = (pictures: any[], videos: any[], fileName?: string) => {
    downloadImageAndVideo(fileName || '告警图片和视频', [...videos?.map((video) => video?.fileUrl), ...pictures?.map((picture) => picture?.fileUrl)]);
}

const AlarmAttachment: React.FC<AlarmAttachmentProps> = (props) => {
    const [state, setState] = useState<any>({alarmInfoDraw: null});

    const {pictures, videos} = getPicturesAndVideoByAnnexList(props);

    const onClick = (type: 'img' | 'video') => {
        // download({url: type === 'img' ? props.pictures : props.videos});
        setState({alarmInfoDraw: Object.assign({__uuid: uuid()}, props, {pictures, videos})});
    }

    return (
        <Fragment>
            <div className={styles.alarmAttachment}>
                <div className={styles.item} onClick={() => onClick(`img`)}>
                    <img src={imgIcon} alt={`图片`}/> {pictures?.length || 0}
                </div>
                <div className={styles.item} onClick={() => onClick(`video`)}>
                    <img src={videoIcon} alt={`视频`}/> {videos?.length || 0}
                </div>
            </div>
            <SoftTableModal state={state} dispatch={() => {
            }}/>
        </Fragment>
    )
};

export default AlarmAttachment;