import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Avatar, Menu, Spin, Drawer} from 'antd';
import {LogoutOutlined, UserOutlined} from '@ant-design/icons';
import HeaderDropdown from './index';
import {useRecoilState} from "recoil";
import CurrentUser, {CurrentUserState} from "@store/CurrentUser";
import {loginOut} from "@/layouts/UserLayout/api";
import {ChangePasswordForm} from "@/layouts/UserLayout/ChangePassword";
import styles from '../RightLayout/index.module.less';


const UserDropdown: React.FC<{}> = (props: any) => {
    /**
     * 设置是否可见
     */
    const [visible, setVisible] = useState(false);
    /**
     * 用户信息
     */
    const [currentUser, setCurrentUser]: CurrentUserState = useRecoilState(CurrentUser);

    const onMenuClick = (event: any) => {
        const {key} = event;
        if (key === 'logout') {
            /**
             * 将保存的用户信息释放
             */
            setCurrentUser({});
            loginOut()
            props.history.replace({
                pathname: '/login',
            });
            return;
        }

        if (key === 'center') {
            setVisible(true);
        }
    };

    const menuHeaderDropdown = (
        <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
            {currentUser?.name && (
                <Menu.Item key="center">
                    <UserOutlined/>
                    个人设置
                </Menu.Item>
            )}

            <Menu.Item key="logout">
                <LogoutOutlined/>
                退出登录
            </Menu.Item>
        </Menu>
    );

    return currentUser && currentUser.name ? (
        <HeaderDropdown overlay={menuHeaderDropdown}>
            <span className={`${styles.action} ${styles.account}`}>
              <Avatar size="small" className={styles.avatar} src={currentUser?.avatar || '/avatar.png'} alt="avatar"/>
              <span className={`${styles.name} anticon`}>{currentUser?.name}</span>
                <Drawer
                    destroyOnClose={true}
                    contentWrapperStyle={{paddingTop: 50}}
                    zIndex={10000}
                    width={600}
                    title="个人设置"
                    placement="right"
                    onClose={() => {
                        setVisible(false);
                    }}
                    visible={visible}
                >
               <ChangePasswordForm/>
            </Drawer>
            </span>
        </HeaderDropdown>
    ) : (
        <span className={`${styles.action} ${styles.account}`}>
        <Spin
            size="small"
            style={{
                marginLeft: 8,
                marginRight: 8,
            }}
        />
      </span>
    );
};

export default withRouter(UserDropdown);
