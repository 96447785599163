/**********************************************************************
 *
 * @模块名称: SoftTable Form
 *
 * @模块用途: SoftTable Form  表单配置
 *
 * @创建人: diaojw
 *
 * @date: 2021-11-17 17:19:45
 *
 **********************************************************************/

import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {FormForTableProps} from "../typing";
import ProDescriptions from '@ant-design/pro-descriptions';
import {FormInstance} from 'antd/lib/form';


const SoftTableFrom: React.FC<FormForTableProps> = forwardRef((props, ref) => {
    const {formData} = props;
    const formRef = useRef<FormInstance>(null);

    useImperativeHandle(ref, () => ({
        values: async () => formRef.current?.validateFields()
    }));

    return (
        <React.Fragment>
            <ProDescriptions bordered labelStyle={{minWidth: 90}} column={1} title="" size='small'>
                <ProDescriptions.Item
                    label="车牌号">
                    {formData.licPlateNum}
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    label="所属企业">
                    {formData.customerName}
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    label="报警类型">
                    {formData.alarmType}
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    label="报警内容">
                    {formData.alarmMessage}
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    label="报警时间">
                    {formData.createAt}
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    label="地理位置">
                    {formData.location}
                </ProDescriptions.Item>
            </ProDescriptions>
        </React.Fragment>
    );
});

export default SoftTableFrom;
