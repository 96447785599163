import ProList from '@ant-design/pro-list';
import React, {useState, Fragment} from 'react';
import {realTimeAlertList} from "@/layouts/HeaderNotice/api";
import AlarmAttachment from "@components/AlertInfo/AlarmAttachment";
import {Button} from "antd";
import SoftTableDetail from "@pages/alarmMangage/components/SoftTableDetail";
import {
    AlertHandleTypeEnum,
    assembleAlertDetailStore,
    checkAlertHasHandle
} from "@share/assembleAlertDetail";
import avatar from "@components/AlertInfo/assets/point.svg";

export interface NoticeIconTabProps {
    handleRequest?: (...props: any) => void;
    actionRef?: any;
}

const NoticeList: React.SFC<NoticeIconTabProps> = (props) => {
    const [store, setStore] = useState<any>({});
    return (
        <Fragment>
            <ProList<any>
                actionRef={props.actionRef}
                size={`small`}
                pagination={false}
                metas={{
                    title: {
                        dataIndex: 'alarmMessage',
                        render: (_, item: any) => {
                            return item.alarmMessage;
                        }
                    },
                    subTitle: {},
                    type: {},
                    avatar: {
                        dataIndex: 'selfNumber',
                        render: () => {
                            return <img alt='' style={{marginTop: 8}} width={8} height={8} src={avatar}/>;
                        }
                    },
                    content: {
                        dataIndex: 'selfNumber',
                        render: (_, item: any) => {
                            return (
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 180,
                                        }}
                                    >
                                        <div>{item.createAt}</div>
                                    </div>
                                    <div
                                        style={{
                                            width: 100,
                                        }}
                                    >
                                        <div>{item.selfNumber}</div>
                                    </div>
                                    <div
                                        style={{
                                            width: 100,
                                        }}
                                    >
                                        <div>{item.licPlateNum}</div>
                                    </div>
                                </div>
                            )
                        }
                    },
                    actions: {
                        dataIndex: 'selfNumber',
                        render: (_, item: any) => {
                            const _type = checkAlertHasHandle(item);
                            return [
                                <AlarmAttachment {...item}/>,
                                _type
                                    ? <Button onClick={() => {
                                        setStore(assembleAlertDetailStore(item));
                                    }} type={`link`}>{AlertHandleTypeEnum[_type]}</Button>
                                    : null
                            ]
                        }
                    },
                }}
                headerTitle=""
                request={async (params: any) => {
                    const res = await realTimeAlertList(Object.assign({}, params, {
                        current: 1,
                        pageSize: 10000,
                        needProcess: false
                    }));
                    props.handleRequest && props.handleRequest(res);
                    return {
                        data: res?.list || [],
                        total: res?.total || 0
                    }
                }}
            />
            <SoftTableDetail state={store} dispatch={() => {
                // @ts-ignore
                props.actionRef.current.reload();
            }}/>
        </Fragment>
    );
};

export default NoticeList;
