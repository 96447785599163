/**********************************************************************
 *
 * @模块名称: api
 *
 * @模块用途: api 定义
 *
 * @创建人:
 *
 * @date: 2021-12-07 10:53:44
 *
 **********************************************************************/
import {checkMsg, checkStatue, post} from "@httpClient/index";
import {makeParamsProper} from "@gaopeng123/utils";
import {traverseTree} from "@components/DeviceTree/utils";
import {message} from "antd";
import { carInfoWorking } from "@pages/operationSerive/vehicleMangage/api";

/**
 * 车的树状数据
 */
export const carTree = async (params: any, defaultSelectedKey?: string) => {
    const res = await post('/iot-xingtu20-service-tenant/car/operation/tree/v2', {params: makeParamsProper(params)});
    if (checkStatue(res)) {
        const data = res.data?.result;
        return traverseTree(
            [Object.assign({customerId: 1, parentId: 0, customerName: '全部', disabled: true}, data)],
            {
                titleKey: 'customerName',
                idKey: 'customerId',
            } as any,
            defaultSelectedKey
        );
    } else {
        message.warn(checkMsg(res));
        return [];
    }
}

/**
 * 单个作业车辆的数据
 * @param id
 */
export const carInfo = carInfoWorking;
/**
 * 告警表
 */
export const alertList = async (params: any) => {
    const res = await post(`/iot-xingtu20-service-tenant/alarm/list`, {
        body: {
            "carId": params.carId,
            "deviceType": params.deviceType, // 1:aiBox;2:terminal;3:all，默认3
            pageNum: 1,
            pageSize: 10000,
        }
    });
    if (checkStatue(res)) return res?.data?.list || [];
    message.warn(checkMsg(res));
    return [];
}
/**
 * 电子围栏告警
 * @param params
 */
export const alertFenceList = async (params: any) => {
    const res = await post(`/iot-xingtu20-service-tenant/alarm/fenceList`, {
        body: {
            "licPlateNum": params.licPlateNum,
            "deviceType": params.deviceType, // 1:aiBox;2:terminal;3:all，默认3
        }
    })
    if (checkStatue(res)) return res?.data?.list || [];
    message.warn(checkMsg(res));
    return [];
}
