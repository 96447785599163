/**********************************************************************
 *
 * @模块名称: utils
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2021/12/7 11:39
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import {TreeNode, TreeNodeConfig} from "@components/DeviceTree/typing";

/**
 * 遍历当前树
 */

const defaultTreeNodeConfig = {
    childrenKey: 'children', // children的对象key
    titleKey: 'name', // 展示数据
    idKey: 'id', // 标识字段
    parentKey: '', // 父节点的key值
    // render: ()=> {}
}
/**
 * 具体的遍历函数 全量遍历
 * @param treeData
 * @param config
 */
export const recursionTree = (treeData: Array<TreeNode>, config: TreeNodeConfig): Array<TreeNode> | null => {
    const {idKey, titleKey, childrenKey, parentKey} = config;
    if (treeData?.length) {
        return treeData.map((itemTree) => {
            return config.render ? config.render(itemTree, config) : itemTree
        })
    }
    return null;
}
/**
 * 遍历树
 * @param treeData
 * @param config
 */
type traverseTreeRetrun = {
    treeData: Array<TreeNode>,
    defaultSelectedNode: Array<TreeNode>,
}
export const traverseTree = (treeData: Array<TreeNode>, config?: TreeNodeConfig, defaultSelectedKey?: any): any => {
    let defaultSelectedNode: any = null;
    /**
     * 保存每个树的唯一标识 在插入值得时候 能快速的找到 不需要再二次遍历
     */
    const mergeConfig = Object.assign({
        render: (itemTree: TreeNode, config: TreeNodeConfig) => {
            const {idKey, titleKey, childrenKey, parentKey, canLoad} = config;
            const currentKey = parentKey ? `${parentKey}-${itemTree.carId !== undefined ? itemTree.carId : itemTree[idKey]}` : `${itemTree[idKey]}`;
            if (currentKey?.endsWith(defaultSelectedKey)) defaultSelectedNode = Object.assign({key: currentKey}, itemTree);
            return Object.assign({}, itemTree, {
                key: currentKey,
                title: itemTree[titleKey],
                // 树结构支持4层以上
                isLeaf: canLoad ? false : (currentKey.split('-'))?.length >= 3 && !itemTree[childrenKey]?.length ? true : false,
                children: recursionTree(itemTree[childrenKey], Object.assign({}, config, {parentKey: currentKey}))
            });
        }
    }, defaultTreeNodeConfig, config);
    // 遍历后获取的新树
    const _treeData: any = recursionTree(treeData, mergeConfig);
    /**
     * 返回对象还是数组
     */
    return defaultSelectedNode ? {
        treeData: _treeData,
        defaultSelectedNode: defaultSelectedNode
    } : _treeData;
};
/**
 * 添加树
 * @param treeData
 * @param config
 */
export const addTreeNode = (treeData: Array<TreeNode>, config?: TreeNodeConfig) => {
    const mergeConfig = Object.assign({
        render: (itemTree: TreeNode, config: TreeNodeConfig) => {
            const {idKey, titleKey, childrenKey, parentKey} = config;
            const currentKey = parentKey ? `${parentKey}-${itemTree[idKey]}` : itemTree[idKey];
            return Object.assign({}, itemTree, {
                key: currentKey,
                title: itemTree[titleKey],
                children: recursionTree(itemTree[childrenKey], Object.assign({}, config, {parentKey: currentKey}))
            });
        }
    }, defaultTreeNodeConfig, config)
}

/**
 * 打平tree装结构数据
 * @param treeData
 * @param config
 */
export const flatTree = (treeData: Array<TreeNode>, config?: TreeNodeConfig, floor = 3, treeNodeArr = []): any[] => {
    for (let tree of treeData) {
        if (tree?.children?.length) {
            flatTree(tree?.children, config, floor, treeNodeArr);
        } else {
            // @ts-ignore
            treeNodeArr.push(tree);
        }
    }
    return treeNodeArr;
};