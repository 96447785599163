/**********************************************************************
 *
 * @模块名称: api
 *
 * @模块用途: api 定义
 *
 * @创建人: diaojw
 *
 * @date: 2021-11-17 17:19:45
 *
 **********************************************************************/
import {get, post, put, del} from "@/httpClient";
import {message} from "antd";

// 报警处理  /alarm/process
export const reqAlarmProcess = async (data: any) => {
    return await put('/iot-xingtu20-service-tenant/alarm/process', {params: data});
};
// 报警历史列表 /alarm/history
export const reqAlarmHistoryList = async (params: any) => {
    return await post('/iot-xingtu20-service-tenant/alarm/history', {body: params});
};
// 报警实时列表 /alarm/list
export const reqAlarmCurrentList = async (params: any) => {
    return await post('/iot-xingtu20-service-tenant/alarm/list', {body: params});
};
// 报警实类型
export const reqAlarmTypeList = async (data: object) => {
    return await get('/iot-xingtu20-service-tenant/alarm/alarmTypes', {params: data});
};

// 车辆列表接口
export const vehicleMangageTableList = async (params: any) => {
    const res = await post(`/iot-common-device-tenant/api/nebula/device/aiot/devices/vehicle/v1?pageNum=${params.current}&pageSize=${params.pageSize}`, {body: params});
    if (res.code === 200) {
        return {
            data: res.data.content,
            total: res.data.totalSize,
            success: true
        }
    }
    message.warn(res.msg);
    return {
        data: [],
    }
};
/**
 * 删除接口
 */
export const delSoftTable = async (ids: any) => {
    return await del('/iot-common-device-tenant/api/nebula/device/v1', {body: ids});
};
// 新增车辆接口
export const vehicleAdd = async (params: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/v1', {body: params});
};
// 新增车辆接口---xingtu
export const vehicleXingtuAdd = async (params: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/addXtDevice/v1', {body: params});
};
