/**********************************************************************
 *
 * @模块名称: BaseAmap
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2021/12/8 16:09
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { Result } from "antd";
import { AMapProps } from "@components/AMapMap/typing";

declare const AMap: any;
const BaseAmap: React.FC<AMapProps> = (props) => {
    const divRef = useRef<any>({});
    const mapRef = useRef<any>();
    const [hasAMap, setHasAMap] = useState<boolean>(false);

    useEffect(() => {
        if (AMap) {
            const createMap = (beforeInitData: any) => {
                const map = new AMap.Map(divRef.current, Object.assign({
                    mapStyle: 'amap://styles/normal',
                    resizeEnable: true,
                    theme: 'dark',
                    animateEnable: false,
                    zoom: 13
                }, props?.AMapTheme, props.AMapOption));
                if (props?.initMap) props?.initMap(map, beforeInitData);
                mapRef.current = map;
            }

            if (props.beforeInit) {
                props.beforeInit({}).then((res: any) => {
                    createMap(res);
                })
            } else {
                createMap(null);
            }
        } else {
            setHasAMap(true);
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.destroy();
            }
        }
    }, []);
    return (
        <React.Fragment>
            <div className={props.className} style={Object.assign({}, { width: '100%', height: '100%' }, props.style)}
                 ref={divRef}></div>
            {
                hasAMap
                    ? <Result
                        status="warning"
                        title="地图未加载成功，请检查网络连接状态！"
                        extra={null}
                    />
                    : null
            }

        </React.Fragment>

    )
}

export default BaseAmap;