/**********************************************************************
 *
 * @模块名称: SoftTableModal
 *
 * @模块用途: SoftTableModal  模态框配置
 *
 * @创建人: diaojw
 *
 * @date: 2021-11-17 17:19:45
 *
 **********************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import {FormForTableRef, ModalForTableProps, StoreEnum} from "../typing";
import SoftTableFrom from "./SoftTableForm";
import {Button, Drawer} from 'antd';
import ImageVideoTabs from "@pages/alarmMangage/components/imageVideoTabs";
import {
    downloadAllData,
    downloadImageData,
    downloadVideoData,
    getFileName
} from "@components/AlertInfo/AlarmAttachment";

enum Title {
    alarmInfoDraw = '报警信息',
}

const SoftTableModal: React.FC<ModalForTableProps> = (props) => {
    const {state, dispatch} = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const formRef = useRef<FormForTableRef | any>(null);

    // 报警信息
    useEffect(() => {
        if (state[StoreEnum.alarmInfoDraw]) {
            setFormData(state[StoreEnum.alarmInfoDraw]);
            setIsModalVisible(true);
        }
    }, [state[StoreEnum.alarmInfoDraw]]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    // 标题
    const titleHandler = () => {
        return (
            <span>
                 <i className='iconfont icon-baojing'></i>&nbsp;
                {Title.alarmInfoDraw}
            </span>
        )
    }

    return (
        <Drawer
            width={600}
            contentWrapperStyle={{paddingTop: 50}}
            title={titleHandler()}
            placement="right"
            destroyOnClose
            visible={isModalVisible}
            onClose={handleCancel}
            footer={
                <div className='drawer-footer modal-footer'>
                    <Button type='primary'
                            onClick={() => downloadAllData(formData?.pictures, formData?.videos, getFileName(formData))}
                            disabled={formData?.pictures?.length === 0 && formData?.videos?.length === 0}>下载所有</Button>&nbsp;&nbsp;&nbsp;
                    <Button type='primary' onClick={() => downloadImageData(formData?.pictures, getFileName(formData))}
                            disabled={formData?.pictures?.length === 0}>下载图片</Button>&nbsp;&nbsp;&nbsp;
                    <Button type='primary' onClick={() => downloadVideoData(formData?.videos, getFileName(formData))}
                            disabled={formData?.videos?.length === 0}>下载视频</Button>
                </div>
            }>
            <SoftTableFrom ref={formRef} formData={formData}/>
            <ImageVideoTabs state={state} dispatch={dispatch} formData={formData}/>
        </Drawer>
    );
};

export default SoftTableModal;
