/**********************************************************************
 *
 * @模块名称: api
 *
 * @模块用途: api 定义
 *
 * @创建人: ligm12
 *
 * @date: 2021-12-07 10:57:59
 *
 **********************************************************************/
import { checkMsg, checkStatue, get, post } from "@httpClient/index";
import { message } from "antd";
import { traverseTree } from "@components/DeviceTree/utils";
import { makeParamsProper } from "@gaopeng123/utils";
import { getCarTypeAndCodeByCarId } from "@share/utils";

/**
 * 车的树状数据
 */
export const carTree = async (params: any, defaultSelectedKey?: string) => {
    // const res = await post('/iot-xingtu20-service-tenant/car/aibox/tree/v2', {params: makeParamsProper(params)});
    const res = await post('/iot-xingtu20-service-tenant/car/vehicle/tree/v2', { params: makeParamsProper(params)});
    if (checkStatue(res)) {
        const data = res.data?.result;
        return traverseTree(
            [Object.assign({ customerId: 1, parentId: 0, customerName: '全部', disabled: true }, data)],
            {
                titleKey: 'customerName',
                idKey: 'customerId',
            } as any,
            defaultSelectedKey
        );
    } else {
        message.warn(checkMsg(res));
        return [];
    }
}

export const realtimeCarTree = async (params: any, defaultSelectedKey?: string, opt?: any, abortController?: AbortController) => {
    // const res = await post('/iot-xingtu20-service-tenant/car/aibox/tree/v2', {params: makeParamsProper(params)});
    const url = `/iot-xingtu20-service-tenant/car/vehicle/tree/${opt.isFirstLoad ? 'v3' : 'v2'}`;
    const fn = opt.isFirstLoad ? get : post;
    const res = await fn(url, {
        params: {
            ...makeParamsProper(params),
            treeType: 1
        },
        abortController: abortController
    });
    if (checkStatue(res)) {
        const data = res.data?.result || res.data;
        return traverseTree(
            [Object.assign({ customerId: 1, parentId: 0, customerName: '全部', disabled: true }, data)],
            {
                titleKey: 'customerName',
                idKey: 'customerId',
            } as any,
            defaultSelectedKey
        );
    } else {
        message.warn(checkMsg(res));
        return [];
    }
}
/**
 * 获取所有的车辆状态
 */
export const getAllListStatus = async () => {
    const res = await post('/iot-xingtu20-service-tenant/car/vehicle/list/v4');
    if (checkStatue(res)) {
        return res?.data?.list
    } else {
        return []
    }
}

export const trackPlaybackCarTree = async (params: any, defaultSelectedKey?: string, opt?: any, abortController?: AbortController) => {
    // const res = await post('/iot-xingtu20-service-tenant/car/aibox/tree/v2', {params: makeParamsProper(params)});
    const res = await post('/iot-xingtu20-service-tenant/car/vehicle/tree/v2', {
        params: {
            ...makeParamsProper(params),
            treeType: 2
        },
        abortController: abortController
    });
    if (checkStatue(res)) {
        const data = res.data?.result;
        return traverseTree(
            [Object.assign({ customerId: 1, parentId: 0, customerName: '全部', disabled: true }, data)],
            {
                titleKey: 'customerName',
                idKey: 'customerId',
            } as any,
            defaultSelectedKey
        );
    } else {
        message.warn(checkMsg(res));
        return [];
    }
}

export const replayCarTree = async (params: any, defaultSelectedKey?: string, opt?: any, abortController?: AbortController) => {
    // const res = await post('/iot-xingtu20-service-tenant/car/aibox/tree/v2', {params: makeParamsProper(params)});
    const res = await post('/iot-xingtu20-service-tenant/car/vehicle/tree/v2', {
        params: {
            ...makeParamsProper(params),
            treeType: 4
        },
        abortController: abortController
    });
    if (checkStatue(res)) {
        const data = res.data?.result;
        return traverseTree(
            [Object.assign({ customerId: 1, parentId: 0, customerName: '全部', disabled: true }, data)],
            {
                titleKey: 'customerName',
                idKey: 'customerId',
                canLoad: true
            } as any,
            defaultSelectedKey
        );
    } else {
        message.warn(checkMsg(res));
        return [];
    }
}

export const videoLiveCarTree = async (params: any, defaultSelectedKey?: string, opt?: any, abortController?: AbortController) => {
    // const res = await post('/iot-xingtu20-service-tenant/car/aibox/tree/v2', {params: makeParamsProper(params)});
    const res = await post('/iot-xingtu20-service-tenant/car/vehicle/tree/v2', {
        params: {
            ...makeParamsProper(params),
            treeType: 5
        },
        abortController: abortController
    });
    if (checkStatue(res)) {
        const data = res.data?.result;
        return traverseTree(
            [Object.assign({ customerId: 1, parentId: 0, customerName: '全部', disabled: true }, data)],
            {
                titleKey: 'customerName',
                idKey: 'customerId',
                canLoad: true
            } as any,
            defaultSelectedKey
        );
    } else {
        message.warn(checkMsg(res));
        return [];
    }
}

export const workhourCarTree = async (params: any, defaultSelectedKey?: string, opt?: any, abortController?: AbortController) => {
    // const res = await post('/iot-xingtu20-service-tenant/car/aibox/tree/v2', {params: makeParamsProper(params)});
    const res = await post('/iot-xingtu20-service-tenant/car/vehicle/tree/v2', {
        params: {
            ...makeParamsProper(params),
            treeType: 8
        },
        abortController: abortController
    });
    if (checkStatue(res)) {
        const data = res.data?.result;
        return traverseTree(
            [Object.assign({ customerId: 1, parentId: 0, customerName: '全部', disabled: true }, data)],
            {
                titleKey: 'customerName',
                idKey: 'customerId',
            } as any,
            defaultSelectedKey
        );
    } else {
        message.warn(checkMsg(res));
        return [];
    }
}

export const homeCarTree = async (params: any, defaultSelectedKey?: string, abortController?: AbortController) => {
    // const res = await post('/iot-xingtu20-service-tenant/car/aibox/tree/v2', {params: makeParamsProper(params)});
    const res = await post('/iot-xingtu20-service-tenant/car/vehicle/tree/v2', {
        params: {
            ...makeParamsProper(params),
            treeType: 9
        },
        abortController
    });
    if (checkStatue(res)) {
        const data = res.data?.result;
        return traverseTree(
            [Object.assign({ customerId: 1, parentId: 0, customerName: '全部', disabled: true }, data)],
            {
                titleKey: 'customerName',
                idKey: 'customerId',
            } as any,
            defaultSelectedKey
        );
    } else {
        message.warn(checkMsg(res));
        return [];
    }
}

/**
 * 单个车的数据
 * @param id
 */
export const carInfo = async (id: string) => {
    // const res = await post(`/iot-xingtu20-service-tenant/car/aibox/${id}`);
    const res = await post(`/iot-xingtu20-service-tenant/car/vehicle/${id}`);
    if (checkStatue(res)) {
        const { deviceType, deviceCode } = getCarTypeAndCodeByCarId(id);
        return Object.assign({
            __carId: id,
            __deviceType: deviceType,
            __deviceCode: deviceCode
        }, res?.data?.result || {})
    }
    message.warn(checkMsg(res));
}

/**
 * 通道数据获取
 */
export const aisleList = async (params: any) => {
    const res = await post(`/iot-common-device-tenant/api/nebula/device/bind/list/v1`, {
        body: {
            "masterDevcieCode": params.deviceCode,
            "masterDevcieTypeName": params.deviceTypeName
        }
    });
    if (checkStatue(res)) return res.data;
    message.warn(checkMsg(res));
    return [];
}