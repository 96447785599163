/**********************************************************************
 *
 * @模块名称: Menus
 *
 * @模块用途: Menus
 *
 * @date: 2021/8/2 18:42
 *
 * @版权所有: pgli
 *
 **********************************************************************/
import { atom, selector } from 'recoil';
import { get } from "@/httpClient";
import { menuTreeNodeList } from "@/utils/treenode";

//reqDeleteMenu,reqUpdateMenu
export const reqAllMenu = () => {
    return get('/iot-common-auth-tenant/api/nebula/auth/menu/v1/list/all/ancestors');
}

/**
 * 获取菜单列表
 */
export const getMenuList = async () => {
    const res = await reqAllMenu()
    if (!res) return
    if (res.data && res.code === 200) {
        sessionStorage.setItem('menuAllList', JSON.stringify(res.data.sysMenuList))
        let menuList = menuTreeNodeList(res.data.sysMenuList)
        sessionStorage.setItem('menuAllListParams', JSON.stringify(menuList))
        return res.data.sysMenuList
    }
};

const defaultMenus = () => {
    return sessionStorage.menuAllList ? JSON.parse(sessionStorage.menuAllList) : [];
}

const setDefaultMenus = (menus: Array<any>) => {
    sessionStorage.setItem('menuAllList', JSON.stringify(menus))
}

export const getNewCurrentMenus = (currentMenus: any) => {
    return JSON.parse(JSON.stringify(currentMenus));
}

const MenusState = atom({
    key: 'menus',
    default: defaultMenus(),
});

const CurrentMenus = selector({
    key: 'CurrentMenus',
    get: ({ get }) => {
        return get(MenusState);
    },
    set: ({ set }, newValue) => {
        // 拦截输入 将用户信息写入到Storage
        setDefaultMenus(newValue);
        set(MenusState, newValue);
    },
});


export default CurrentMenus;