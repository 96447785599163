/**
 *  用户登录模块
 */
import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import useOEM from "@/hooks/useOEM";
import { message, notification } from "antd";
import { useSetRecoilState } from "recoil";
import CurrentUser from "@/store/CurrentUser";
import { getMenuList } from "@store/Menus";
import { getFirstPath } from "@httpClient/Global";
import WebSocketManager from "@components/WebSocket/WebSocketManager";
import { encryptPublicKey } from "@/layouts/UserLayout/encryptor";
import { RCLoginJSEncrypt, LoginModuleProps } from '@gaopeng123/rc-login-jsencrypt';
import { accountLogin, getCaptcha, loginByCode, phoneCode, resetPassword } from "@/layouts/UserLayout/api";
import { checkMsg, checkStatue } from "@/httpClient";
import CurrentMenus from "@store/Menus";
import AppDownload from "@/layouts/UserLayout/AppDownload";
import "./styles.less";

const headers = {
    clientId: 'tenant-service',
    secret: 'dsf545sdfar23h89rejkl87oyr13s48er',
    'Content-Type': 'application/json',
};

const UserLayout: React.FC<LoginModuleProps & RouteComponentProps> = (props: any) => {
    /**
     * oem数据消费
     */
    const loginName = useOEM('loginName');

    // 登录后将用户信息缓存到全局变量里面
    // 登录 菜单管理模块 处理第一次跳转的重定向问题
    const login = (menus: any) => {
        setTimeout(() => {
            props.history.push({
                // pathname: '/operationSerive/intellWorking'
                pathname: getFirstPath(menus), // screen
            });
        }, 100);
    };

    /**
     * 保存用户登录信息
     */
    const setCurrentUser = useSetRecoilState(CurrentUser);
    /**
     * 保存菜单信息
     */
    const setCurrentMenus = useSetRecoilState(CurrentMenus);
    /**
     * 获取验证码
     */
    const getCaptchaImage = async () => {
        return await getCaptcha({
            params: {
                width: 80,
                height: 30
            },
            headers: headers
        })
    };
    /**
     * 登录后处理
     * @param res
     */
    const handleLogin = (res: any) => {
        if (res.code === 200 || res.code === 426) {
            sessionStorage.roleHighestLevel = res.data.roleHighestLevel;
            setCurrentUser(Object.assign({
                name: res.data?.username,
                token: res?.data['access_token']
            }, res.data));
            getMenuList().then((menus) => {
                setCurrentUser(Object.assign({
                    name: res.data?.username,
                    token: res?.data['access_token'],
                    isChangePassWord: res.code === 426,
                    menus: menus
                }, res.data));
                setCurrentMenus(menus);
                login(menus);
            })
        } else {
            message.warn(res.msg);
        }
    }

    useEffect(() => {
        WebSocketManager.destroyAll();
        notification.destroy()
    }, []);

    return (
        <RCLoginJSEncrypt
            encryptPublicKey={encryptPublicKey}
            clientId={headers.clientId}
            secret={headers.secret}
            getCaptcha={getCaptchaImage}
            browserRemembersPassword={true}
            handleSubmit={async ({ headers, body, loginType, data, encryptor }: any) => {
                /**
                 * 菜单的第一项 默认为初始页面
                 * 账号登录和短信登录
                 */
                return new Promise((resolve, reject) => {
                    if (loginType?.type === 'phone') {
                        loginByCode({
                            headers: headers,
                            body: { telNumber: data?.phone, phoneCode: data?.code }
                        }).then((res) => {
                            handleLogin(res);
                        });
                    } else {
                        accountLogin({
                            headers: headers,
                            body: body
                        }).then((res) => {
                            handleLogin(res);
                        });
                    }
                });
            }}
            mainStyle={{ backgroundImage: 'url(/logo_bg.webp)' }}
            bodyStyle={{ right: '200px' }}
            keeplogged={false}
            title={loginName}
            agreementProprietary={`盛景智能科技（嘉兴）有限公司`}
            forgotPasswordUrl={true}
            phoneLoginUrl={true}
            onSendSMSVerificationCode={(data: any) => {
                phoneCode(data.phone);
            }}
            onResetPasswordSubmit={(params: any) => {
                // code: ""
                // password: ""
                // phone: ""
                const { data, body } = params
                return new Promise((resolve, reject) => {
                    resetPassword({
                        telNumber: body?.phone,
                        phoneCode: body?.code,
                        password: body?.password,// 目前不需要加密
                    }).then((res) => {
                        if (checkStatue(res)) {
                            resolve(true);
                            message.success(`${checkMsg(res)},请使用新密码登录！`);
                        } else {
                            message.warn(`${checkMsg(res)}`);
                            resolve(false);
                        }
                    });
                }).catch(() => {

                });
            }}
        >
            <div slot="footer" style={{textAlign: 'right'}}>
                <AppDownload/>
            </div>
        </RCLoginJSEncrypt>
    );
};

export default withRouter(UserLayout);
 