/*
@name: ImageVideoTabs
@author: diaojw
@time: 2022-01-07 09:22:47
@description:
*/
import React, {useEffect} from 'react';
import {Tabs,Image } from "antd";
import '../css/imageVideo.scss'
import {Props, StoreEnum} from "@pages/alarmMangage/typing";
import ImageComponents from "@pages/alarmMangage/components/imageComponent";
import VideoComponent from "@pages/alarmMangage/components/videoComponent";
const {TabPane} = Tabs

const ImageVideoTabs: React.FC<Props> = (props) => {
    const {state, dispatch, formData} = props
    const callback = ()=>{

    }
    return (
        <div className='image-video-wrap'>
            <Tabs defaultActiveKey={state ? state[StoreEnum.alarmInfoDraw].type : props.type} onChange={callback} size='large'>
                <TabPane tab="照片" key="image" >
                    <ImageComponents formData={formData}/>
                </TabPane>
                <TabPane tab="视频" key="video" >
                    <VideoComponent formData={formData}/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ImageVideoTabs;
