/**********************************************************************
 *
 * @模块名称: AlertMap
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/2/23 9:51
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import React, {useState, useEffect} from 'react';
import BaseAmap from "@components/AMapMap/BaseAmap";
// @ts-ignore
import alertIcon from "@pages/vehicleMange/TrackPlayback/assets/地图路线报警.svg";
import {isEmptyObject} from "@gaopeng123/utils";

declare const AMap: any;
const AlertMap: React.FC<any> = (props: any) => {
    const {locationPosition} = props;
    const [map, setMap] = useState<any>(null);
    const initMap = (map: any) => {
        setMap(map);
    }
    useEffect(() => {
        if (map && !isEmptyObject(locationPosition)) {
            const icon = new AMap.Icon({
                size: new AMap.Size(24, 24),    // 图标尺寸
                image: alertIcon,  // Icon的图像
                // imageOffset: new AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
                imageSize: new AMap.Size(24, 24)   // 根据所设置的大小拉伸或压缩图片
            });

            const marker = new AMap.Marker({
                position: new AMap.LngLat(locationPosition.longitude, locationPosition.latitude),
                offset: new AMap.Pixel(-12, -12),
                icon: icon, // 添加 Icon 实例
                extData: alert
                // zoom: 13
            });
            map.add(marker);
            map.setFitView();
            return ()=> {
                map && map.remove(marker) && map.clearMap();
            }
        }
    }, [map, locationPosition])
    return (
        <BaseAmap initMap={initMap} AMapOption={props.AMapOption}></BaseAmap>
    )
}

export default AlertMap;