/**********************************************************************
 *
 * @模块名称: index
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2024/2/21 14:11
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 盛景
 *
 **********************************************************************/
import React, { useState, useEffect } from 'react';
import { RcQrcode } from "@gaopeng123/rc-qrcode"
import { Button, Col, Row, Tooltip } from "antd";
import styles from "./styles.module.less";

type AppDownloadProps = {};
const AppDownload: React.FC<AppDownloadProps> = (props) => {

    const [AndroidUrl, setAndroidUrl] = useState('');

    useEffect(() => {
        fetch('https://store.sany.com.cn/sany-store-api/v1/common/checkAppUpgrade?app_name=UBS车智云联&platform_name=Android').then((res) => {
            res.json().then((_RES) => {
                setAndroidUrl(_RES.data.appInfo.app_file_url)
            })
        })
    }, [])


    return (
        <>
            <Button
                size={'small'}
                style={{ fontSize: 14, right: 0, top: 8, paddingRight: 0 }}
                type={`link`}>
                <Tooltip getPopupContainer={() => {
                    return document.body
                }} color={'#fff'} placement="topRight" trigger={'click'}
                         title={<div style={{ width: 400, height: 300, overflow: 'hidden', background: '#fff' }}>
                             <Row>
                                 {
                                     AndroidUrl ? <Col>
                                         <div style={{ textAlign: 'center' }} className={styles.ios}>
                                             <RcQrcode
                                                 QRCodeStylingOptions={{ image: './assets/Android.png' }}
                                                 text={AndroidUrl}
                                             />
                                             Android
                                             <div style={{ fontSize: 12, color: 'red' }}>
                                                 建议使用相机扫码下载<br/>
                                                 微信/支付宝会屏蔽apk文件下载
                                             </div>
                                         </div>
                                     </Col> : null
                                 }
                                 <Col>
                                     <div style={{ textAlign: 'center' }}>
                                         <RcQrcode
                                             QRCodeStylingOptions={{ image: './assets/ios.png' }}
                                             text={'https://apps.apple.com/cn/app/ubs车智云联/id6469469639'}
                                         />
                                         IOS
                                     </div>
                                 </Col>
                             </Row>
                         </div>}>
                    App下载
                </Tooltip>
            </Button>
        </>
    )
}

export default AppDownload;