/**********************************************************************
 *
 * @模块名称: Intercept
 *
 * @模块用途: Intercept 拦截器
 *
 * @date: 2021/7/26 8:26
 *
 * @版权所有: pgli
 *
 **********************************************************************/
import {FetchInterceptorResponse, Intercept, Options} from "@gaopeng123/fetch";
import {getToken, getTanant} from "@httpClient/Global";
import toLogin from "@httpClient/toLogin";
// import { proxy, unProxy } from "ajax-hook";
import { getBeacon } from "@httpClient/Buried";

const getUrl = (url: string) => {
    const str = url.split('/');
    return str[0] + '//' + str[2];
}

const mapHtml = {};

// setInterval(() => {
//     console.log(mapHtml)
// }, 5000)
/**
 * 高德地图拦截
 */
// proxy({
//     //请求发起前进入
//     onRequest: (config, handler) => {
//         // @ts-ignore
//         mapHtml[getUrl(config.url)] = true;
//         handler.next(config);
//     },
//     //请求发生错误时进入，比如超时；注意，不包括http状态码错误，如404仍然会认为请求成功
//     onError: (err, handler) => {
//         // console.log(err.type)
//         handler.next(err)
//     },
//     //请求成功后进入
//     onResponse: (response, handler) => {
//         // console.log(response.response)
//         handler.next(response)
//     }
// })

/**
 * 处理代理地址 此处主要是图片、视频等后端直接返回的是地址
 * @param url
 */
const getTenantUrl = (url: string)=> {
    if (process.env.REACT_APP_BASEURL && !url?.startsWith('http') && !url?.startsWith('webrtc:')) {
        return process.env.REACT_APP_BASEURL + url;
    }
    return url;
}

const intercept: Intercept = {
    request: function (url: string, config: Options) {
        // Modify the url or config here
        if (config?.headers) {
            // 此处定义token 获取其他
            const token = getToken();
            // @ts-ignore
            config.headers.token = token;
            config.headers.clientId = 'tenant-service';
            config.headers.tenant = getTanant();
            config.headers.authorization = `Bearer ${token}`;
            getBeacon();
        }
        return [getTenantUrl(url), config];
    },

    requestError: function (error: Error) {
        console.log('requestError');
        return Promise.reject(error);
    },

    response: async (response: FetchInterceptorResponse): Promise<any> => {
        // Modify the reponse object
        // 此处返回
        if (response.request?.options?.responseType && !response.request?.options?.noModification) {
            // 此处拿到返回值信息 服务端权限异常 有可能放到业务接口中处理状态
            const res = await response.clone()[response.request.options.responseType]();
            // 鉴权失败后退出登录
            if (res?.status === 401 || res?.code === 401 || res?.data?.status === 401) {
                toLogin();
            }
        }
        return new Promise((resolve, reject) => {
            resolve(response);
        });
    },

    responseError: function (error: Error) {
        // Handle an fetch error
        console.log('responseError', error);
        return Promise.reject(error);
    }
};

export default intercept;
