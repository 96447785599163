const findFieldValue = (treeData: any, key: string, value: string | number): string | number | boolean | null => {
    let res: string | number | null = null;
    const fn = (treeData: any, key: string, value: string | number) => {
        if (!treeData?.length) return key;
        for (let index = 0; index < treeData.length; index++) {
            if (treeData[index][key] === value) {
                res = treeData[index];
                break;
            }
            if (treeData[index]?.children?.length) {
                fn(treeData[index].children, key, value);
            }
        }
    };
    fn(treeData, key, value);
    return res;
}

export default findFieldValue
