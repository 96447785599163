/**********************************************************************
 *
 * @模块名称: api
 *
 * @模块用途: api 定义
 *
 * @创建人: diaojw
 *
 * @date: 2021-11-17 17:19:45
 *
 **********************************************************************/
import { get, post, del, patch, checkStatue, checkMsg } from "@/httpClient";
import { valueEnum, TableListItem, AddSoftTableProps, EditSoftTableProps } from "./typing";
import { message } from "antd";
import { download } from "@gaopeng123/utils";
import { AddFuelProps } from "@pages/vehicleMange/FuelConsumption/FuelTyping";
import { downLoadFile, uploadFormData } from "@gaopeng123/fetch";
import { getCarTypeAndCodeByCarId } from "@share/utils";


export const SoftTableList = async (params: any) => {
    const tableListDataSource: TableListItem[] = [];

    const creators = ['付小小', '曲丽丽', '林东东', '陈帅帅', '兼某某'];

    for (let i = 0; i < 5; i += 1) {
        tableListDataSource.push({
            // @ts-ignore
            key: i,
            // @ts-ignore
            name: 'AppName',
            // @ts-ignore
            containers: Math.floor(Math.random() * 20),
            // @ts-ignore
            creator: creators[Math.floor(Math.random() * creators.length)],
            // @ts-ignore
            status: valueEnum[Math.floor(Math.random() * 10) % 4],
            // @ts-ignore
            createdAt: Date.now() - Math.floor(Math.random() * 2000),
            // @ts-ignore
            money: Math.floor(Math.random() * 2000) * i,
            // @ts-ignore
            progress: Math.ceil(Math.random() * 100) + 1,
            // @ts-ignore
            memo: i % 2 === 1 ? '很长很长很长很长很长很长很长的文字要展示要留下尾巴' : '简短备注文案',
        });
    }
    return tableListDataSource;
};
/**
 * 单个作业车辆的数据
 * @param id
 */
export const carInfoWorking = async (id: string) => {
    const res = await post(`/iot-xingtu20-service-tenant/car/operation/${id}`);
    if (checkStatue(res)) {
        const {deviceType, deviceCode} = getCarTypeAndCodeByCarId(id);
        return Object.assign({__carId: id, __deviceType: deviceType, __deviceCode: deviceCode}, res?.data?.result || {})
    }
    message.warn(checkMsg(res));
};

// 车辆列表接口
export const vehicleMangageTableList = async (params: any) => {
    const res = await post(`/iot-common-device-tenant/api/nebula/device/aiot/devices/vehicle/v1?pageNum=${params.current}&pageSize=${params.pageSize}`, {body: params});
    if (res.code === 200) {
        return {
            data: res.data.content,
            total: res.data.totalSize,
            success: true
        }
    }
    message.warn(res.msg);
    return {
        data: [],
    }
};
// 终端列表接口
export const terminalTableList = async (params: any) => {
    const res = await post(`/iot-common-device-tenant/api/nebula/device/aiot/devices/terminal/v1?pageNum=${params.current}&pageSize=${params.pageSize}`, {body: params});
    if (res.code === 200) {
        return {
            data: res.data.content,
            total: res.data.totalSize,
            success: true
        }
    }
    message.warn(res.msg);
    return {
        data: [],
    }
};
// 车辆品牌tree
export const vehicleMangageBrandTree = async (vehicleCode: any) => {
    const res = await get(`/iot-common-device-tenant/api/nebula/device/brand/v1?vehicleCode=${vehicleCode}`);
    if (res.code === 200) {
        return res.data
    }
    message.warn(res.msg);
    return {
        data: [],
    }
};
/**
 * 删除接口
 */
export const delSoftTable = async (ids: any) => {
    return await del('/iot-common-device-tenant/api/nebula/device/v1', {body: ids});
};
// 新增车辆接口
export const vehicleAdd = async (params: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/v1', {body: params});
};
// 新增车辆接口---xingtu
export const vehicleXingtuAdd = async (params: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/addXtDevice/v1', {body: params});
};
// 编辑车辆接口
export const vehicleUpdate = async (params: any) => {
    return await patch('/iot-common-device-tenant/api/nebula/device/v1', {body: params});
};
// 编辑车辆接口---xingtu
export const vehicleXingtuUpdate = async (params: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/updateXtSensorDevice/v1', {body: params});
};
// 绑定终端
export const vehicleBindPda = async (params: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/bind/v1', {body: params});
};
// 解绑终端
export const vehicleUnBindPda = async (params: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/unbind/v1', {body: params});
};

/**
 * 新增接口
 */
export const addSoftTable = async (params: AddSoftTableProps) => {
    return await post('./json/OEM.json', {body: params});
};

/**
 * 编辑接口
 */
export const editSoftTable = async (params: EditSoftTableProps) => {
    return await post('./json/OEM.json', {body: params});
};
/**
 * 车辆上传文件接口---校验
 */
export const reqVehicleValidateUpload = async (data: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/import/xtDeviceValidate/v1', {
        body: data,
        headers: {}
    });
};
/**
 * 车辆上传文件接口---
 */
export const reqVehicleUpload = async (data: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/import/xtDevice/v1', {
        body: data,
        headers: {}
    });
};
/**
 * 终端导出
 * @param data
 */
export const exportVehicle = async (data: any) => {
    const res = await post('/iot-xingtu20-service-tenant/trajectory/export/devices/vehicle/list', {
        body: data,
        noModification: true,
    });
    if (res.headers.get('content-type') === 'application/json') {
        res.clone().json().then((resData: any) => {
            message.warn(resData.data || checkMsg(resData));
        })
    } else {
        res?.clone()?.blob()?.then((blob: Blob) => {
            message.success(`开始下载...`);
            console.info('content-disposition', res.headers.get('content-disposition'));
            const fileName = res.headers.get('content-disposition')?.replace('attachment;filename=', '') || `${'终端管理'}.xlsx`;
            download({blob: blob, fileName: fileName})
        });
    }
}


/**
 * 判断车辆删除终端时是否有任务执行  主要智能托运车
 */
export const checkLock = async (trailerId: string) => {
    return await get(`/iot-xingtu20-service-tenant/trailer/checkLock/${trailerId}`);
}

/**
 * 查询液位高度
 */
type ModelProps = { masterDeviceType: string, masterDeviceCode: string, isPrimary?: string }
export const liquidLevel = async (params: ModelProps) => {
    return await get('/iot-common-device-tenant/api/nebula/device/terModelBind/findLatest', {params: params});
};

export const liquidLevel2 = async (params: ModelProps) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/refueling/calibration/v1', {body: params});
};

/**
 * 铁甲卫士油箱自标定 新版本修改
 * @param params
 */
type ModelProps2 = {
    id: number,
    refuelType: 0 | 1, // 加油事件类型，0加油开始，1加油结束
    fuelTankVolume?: number, // 油箱容量
    increasedOilVolume?: number, // 加油量
}
export const liquidLeve2 = async (params: ModelProps & ModelProps2) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/refueling/calibration/v1', {params: params});
};

/**
 * 模型下发
 * @param params
 */
export const insertModel = async (params: { type: number, [propsName: string]: any }) => {
    return await post('/iot-common-device-tenant/api/nebula/device/terModelBind/insertReq', {body: params});
};
/**
 * 油箱新版自标定
 * @param params
 */
export const insertModel2 = async (params: { id: number }) => {
    return await post('/iot-common-device-tenant/api/nebula/device/smartFuelCapCmd/curve/v1', {body: params});
};

/**
 * 模型查询数后续下发给车辆
 * @param params
 */
export const findModel = async (params: ModelProps) => {
    return await get('/iot-common-device-tenant/api/nebula/device/terModelBind/findModel', {params: params});
};

/**
 * 车辆绑定主副油箱
 * @param params
 */
export const findPrimarys = async (params: ModelProps) => {
    return await get('/iot-common-device-tenant/api/nebula/device/terModelBind/findPrimarys', {params: params});
};
/**
 * 车辆列表包含传感器
 * @param params
 */
export const vehicleTableListHasSensor = async (params: any) => {
    const res = await post(`/iot-common-device-tenant/api/nebula/device/fuelSensor/devices/vehicle/v1`, {body: params});
    if (checkStatue(res)) {
        return {
            data: res.data.content,
            total: res.data.totalSize,
            success: true
        }
    }
    message.warn(checkMsg(res));
    return {
        data: [],
    }
};
/**
 * 模型下发至其他车辆
 * @param params
 */
export const vehicleBatchModel = async (params: ModelProps & { target: Array<{ targetType: number, targetCode: number }> }) => {
    return await post('/iot-common-device-tenant/api/nebula/device/terModelBind/batchInsert', {body: params});
};

/**
 * 查询模型标定时间
 * @param params
 */
export const fuelModelCalibrationTime = async (params: ModelProps, abortController?: AbortController) => {
    return await get('/iot-common-device-tenant/api/nebula/device/terModelBind/findModelTime', {params: params, abortController: abortController});
};

/**
 * 模型下载
 */
export const downLoadFuelModel = async (params: AddFuelProps) => {
    // @ts-ignore
    return downLoadFile('/iot-common-device-tenant/api/nebula/device/fuelSensor/fuelModel/export', {
        body: params,
    });
};

/**
 * 模型上传
 * @param params
 */
export const uploadFuelModel = async (params: FormData) => {
    return uploadFormData('/iot-common-device-tenant/api/nebula/device/terModelBind/insertReqUp', {
        body: params,
    });
};

export const loadFuelcapConnList = async (terminalNo: any) => {
    const res = await get(`/iot-common-device-tenant/api/nebula/device/smartFuelCapCmd/getConnStatus/${terminalNo}?pageNum=1&pageSize=1000`);
    if (res.code === 200) {
        return res.data.content || res.data.list || []
    }
    message.warn(res.msg);
    return []
};
