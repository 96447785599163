/**********************************************************************
 *
 * @模块名称: ScreenEntrance
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/3/8 16:11
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import React from 'react';
import {useHistory} from "react-router-dom";
import {Tooltip} from "antd";
import { useScreenRouter } from '@/hoc/Screen';

type ScreenImgProps = {
    path: string,
    icon: string,
    alt: string,
    [propsName:string]: any,
}
const ScreenImg = (props: ScreenImgProps)=> {
    const history = useHistory();
    const {path, icon, alt} = props;
    return (
        <Tooltip title={props?.menuName || alt} placement={'left'}>
            <img width={20} height={20}
                 style={{marginLeft: 24}}
                 onClick={() => {
                     history.push({
                         pathname: path
                     })
                 }} alt={alt} src={icon}/>
        </Tooltip>
    )
};

const ScreenEntrance: React.FC<any> = (props: any) => {
    const screenRouter = useScreenRouter();
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
            {
                screenRouter?.map((item)=> {
                    return <ScreenImg key={item.id} {...item}/>
                })
            }
        </div>
    )
}

export default ScreenEntrance;