export const getInitialState = () => ({
    ready: false,
    popupEnable: true,
    soundEnable: true,
    volume: 70
})

export const reducer = (state: any, action: any) => {
	switch (action.type) {
		case 'set':
			return {
                ...state,
                ...action.payload
            }
        case 'update':
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    ...action.payload
                }
            }
	}
};
