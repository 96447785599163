import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil'
import App from './App';
import HeadersJS from './headersJS';
import { DynamicLoadScript } from "@gaopeng123/hoc";
import { ConfigProvider } from "antd";
import locale from 'antd/lib/locale/zh_CN'
import 'moment/locale/zh-cn';
import reportWebVitals from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.scss';
import './assets/css/font.css';

/**
 * 注入sentry监听
 */
if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY?.trim() !== 'false') {
    Sentry.init({
        dsn: `${process.env.REACT_APP_SENTRY_DSN}`.trim(),
        // environment 上报的环境 建议 按照 测试、生产区分
        environment: process.env.REACT_APP_ENV,
        integrations: [new BrowserTracing()],
        release: `${process.env.REACT_APP_VERSION_RELEASE}`,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

/**
 * 加载静态资源文件
 * @constructor
 */
function LoadScript(onLoad: any) {
    ReactDOM.render(
        <React.StrictMode>
            <DynamicLoadScript url={HeadersJS} onLoad={onLoad}/>
        </React.StrictMode>,
        document.getElementById('root')
    )
}

async function LoadApp() {
    ReactDOM.render(
        <React.StrictMode>
            <RecoilRoot>
                <ConfigProvider
                    locale={locale}
                >
                    <App/>
                </ConfigProvider>
            </RecoilRoot>
        </React.StrictMode>,
        document.getElementById('root')
    )
}

/**
 * 启动前处理
 * 将外部依赖 加载到header上
 * @returns {Promise<void>}
 */
async function startUp() {
    // LoadScript(async () => {
    //     LoadApp();
    // });
    LoadApp();
}

startUp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals(console.log);
