/**********************************************************************
 *
 * @模块名称: AlertItem
 *
 * @模块作用: 单条告警
 *
 * @创建人: ligm12
 *
 * @date: 2022/1/21 17:27
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
import React from 'react';
import {Col, Row} from 'antd';
import styles from './styles.module.less';

const AlertItem: React.FC<any> = (props: any) => {
    return (
        <div className={styles.alertItem}>
            <div className={styles.title}>
                <span className={styles.text}>{props.alarmMessage}</span>
                <span className={styles.extra}>{props.createAt}</span>
            </div>
            <div className={styles.content}>
                <Row>
                    <Col span={20} className={styles.col}>
                        <span style={{marginRight: 12}}>自&nbsp;编&nbsp;号&nbsp;：{props.selfNumber}</span>
                        <span className={styles.right}>车牌：{props.licPlateNum}</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>地理位置：{props.location}</Col>
                    <Col span={4} className={styles.tbar} onClick={props.onClick}>{props.okText || '处理'}</Col>
                </Row>
            </div>
        </div>
    )
}

export default AlertItem;