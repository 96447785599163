/**********************************************************************
 *
 * @模块名称: ChangePasswordForm
 *
 * @模块作用: 密码修改表单
 *
 * @创建人: ligm12
 *
 * @date: 2022/6/6 14:44
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
 import {Button, Form, Input, message} from 'antd';
 import React, {useImperativeHandle, forwardRef} from 'react';
 import {useRecoilValue} from "recoil";
 import CurrentUser from "@store/CurrentUser";
 import {changePassWordApi} from "@/layouts/UserLayout/ChangePassword/api";
 import encryptor from "@/layouts/UserLayout/encryptor";
 import toLogin from "@httpClient/toLogin";
 import styles from './styles.module.less';
 
 const ChangePasswordForm: React.FC<any> = forwardRef((props, ref) => {
     const [formRef] = Form.useForm();
 
     const currentUser: any = useRecoilValue(CurrentUser);
 
     const handleChange = () => {
         message.destroy();
         message.success(`密码修改成功，请使用新密码进行登录`);
         setTimeout(() => {
             toLogin();
         }, 2000);
     }
 
     const changePassword = () => {
         return new Promise((resolve, reject) => {
             formRef?.validateFields().then((res) => {
                 const {password, newPassword} = res;
                 if (password !== newPassword) {
                     changePassWordApi({
                         userId: currentUser.userId,
                         password: encryptor.encrypt(password),
                         newPassword: encryptor.encrypt(newPassword),
                     }).then((res: any) => {
                         if (res) {
                             resolve(res);
                             handleChange();
                         }
                     });
                 } else {
                     message.warn(`密码不能相同`);
                 }
             });
         })
     }
 
     useImperativeHandle(ref, () => ({
         changePassword: changePassword,
     }));
 
     const onFinish = (values: any) => {
         changePassword();
     };
 
     return (
         <Form
             name="basic"
             form={formRef}
             labelCol={{span: 4}}
             wrapperCol={{span: 19}}
             initialValues={{}}
             autoComplete="off"
             onFinish={onFinish}
             size={`large`}
             className={styles.form}
         >
             <Form.Item
                 label="密码"
                 name="password"
                 help={`密码必须包含数字、特殊字符、大写字母、小写字母，并且大于8位`}
                 rules={[{
                     required: true,
                     message: '请输入旧密码',
                     // pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,}')
                 }]}
             >
                 <Input.Password minLength={8}/>
             </Form.Item>
 
             <Form.Item
                 label="新密码"
                 name="newPassword"
                 help={`密码必须包含数字、特殊字符、大写字母、小写字母，并且大于8位`}
                 rules={[{
                     required: true,
                     message: '请输入新密码',
                     pattern: new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,}')
                 }]}
             >
                 <Input.Password minLength={8}/>
             </Form.Item>
             {
                 ref ? null : <Form.Item wrapperCol={{offset: 19, span: 4}}>
                     <Button type="primary" htmlType="submit">
                         确认修改
                     </Button>
                 </Form.Item>
             }
         </Form>
     );
 })
 
 export default ChangePasswordForm;
 