/**********************************************************************
 *
 * @模块名称: utils
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/6/6 15:28
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
 import JSEncrypt from 'jsencrypt';

 export const encryptPublicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANL378k3RiZHWx5AfJqdH9xRNBmD9wGD2iRe41HdTNF8RUhNnHit5NpMNtGL0NPTSSpPjjI1kJfVorRvaQerUgkCAwEAAQ==';
 
 const encryptor = new JSEncrypt();// 设置公钥
 encryptor.setPublicKey(encryptPublicKey); // 设置公钥
 
 export default encryptor;
 