/**
 * 布局管理
 */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import ProLayout, { ProBreadcrumb } from '@ant-design/pro-layout';
import proSettings from "@/defaultSettings" ;
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import RightLayout from '../RightLayout/index';
import useOEM from "@/hooks/useOEM";
import { RouteWithModuleRoutes } from '@gaopeng123/hoc';
import { MenuDataItem } from "@ant-design/pro-layout/lib/typings";
import CurrentMenus, { getNewCurrentMenus } from "@store/Menus";
import { ConfigProvider, message, Tabs } from "antd";
import findFieldValue from "@/utils/menuPathtoName";
import Icon from "@components/Icon";
import { defaultRouter, getCurrentUserFromStorage, getFirstPath } from "@httpClient/Global";
import { isScreenRouter } from "@hoc/Screen";
import shu_gen_logo from "@/assets/shu_gen_logo.svg";
import { useRecoilValue } from "recoil";
import { menuTreeNodeList } from "@/utils/treenode";
import { debounce } from "@gaopeng123/utils";
import './styles.less';
import HocRouter from "@store/HocRouter";

const { TabPane } = Tabs;
/**
 * 创建icon图标
 * @param icon
 */
const createIcon = (icon: string): React.ReactNode | undefined => {
    return icon ? <Icon type={icon}/> : '';
};

const BasicLayout = (props: any) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    /**
     * 设置重定向数据 默认的加载页面
     */
    const [pathname, setPathname] = useState<string>(props.history.location.pathname);
    /**
     * 路由管理
     */
    const [router, setRouter] = useState<Array<any>>([]);
    /**
     * OEM数据
     */
    const menusLogo = useOEM('menusLogo');
    /**
     * 项目名称
     */
    const menusName = useOEM('loginName');

    const currentMenus = useRecoilValue(CurrentMenus);

    const hocRouter = useRecoilValue(HocRouter);

    const _location = useLocation()
    let initialPanes: any = [];

    const [tagsItem, setTagsItem] = useState<any>({
        activeKey: null,
        panes: initialPanes
    });

    /**
     * 卸载缓存控制  modfiy by ligm12 for keep-alive router at 20210220
     */
    // 不支持的路由
    const UNSUPPORTED = ['/vehicleMange/replayModule', '/vehicleMange/videoLive'];
    const [uninstallKeepAliveKeys, setUninstallKeepAliveKeys] = useState<Array<string>>(UNSUPPORTED);

    useEffect(()=> {
        if (hocRouter.length) {
            setUninstallKeepAliveKeys([...UNSUPPORTED, ...hocRouter])
        }
    }, [hocRouter])

    useEffect(() => {
        let activeKey = _location.pathname
        const route: any = findFieldValue(router, 'path', activeKey);
        if (route?.hideInMenu) {
            const path = route.path;
            const pathname = path.substr(0, path.lastIndexOf('/'));
            setPathname(pathname as string);
            if (!isLoaded) {
                props.history.push({
                    pathname: pathname
                })
            }
            return;
        }
        const name = route?.name;
        let resFlag = tagsItem.panes.some((item: any) => {
            if (item.title === name) {
                return true
            } else {
                return false
            }
        })
        if (!resFlag && name) {
            tagsItem.panes.push({ title: name, content: activeKey, key: activeKey, closable: true });
        }
        setUninstallKeepAliveKeys(UNSUPPORTED);
        setTagsItem({ ...tagsItem, activeKey });
        setPathname(activeKey as string);
    }, [_location, router])

    /**
     * 重定向到第一页
     */
    const redirect = (menuData: MenuDataItem) => {
        // @ts-ignore
        if (pathname === '/pages' || (defaultRouter === '/pages' && pathname === '/')) {
            setTimeout(() => {
                const firstPath = getFirstPath(menuData) as string;
                setPathname(firstPath);
                props.history.push({
                    pathname: firstPath
                })
            });
        }
    };

    useEffect(() => {
        if (currentMenus.length > 0) {
            let menuDatass = menuTreeNodeList(getNewCurrentMenus(currentMenus));
            const menuDateNotScreen = menuDatass?.filter((item: any) => !isScreenRouter(item))
            setRouter(menuDateNotScreen as Array<any>);
            redirect(menuDateNotScreen);
            setTimeout(() => {
                setIsLoaded(true);
            })
        }
    }, [currentMenus])

    // 切换 标签 tags
    const onChangeHandler = (activeKey: any) => {
        props.history.push({
            pathname: activeKey
        })
    }
    // 关闭tags
    const onEditHandler = (targetKey: any, action: any) => {
        removeHandler(targetKey);
    }
    // 关闭tags 函数
    const removeHandler = (targetKey: any) => {
        if (tagsItem.panes.length <= 1) {
            message.error('不可全部关闭！')
            return
        }
        // 清掉keepAlive /*modfiy by ligm12 for keep-alive router at 20210220*/
        setUninstallKeepAliveKeys([targetKey]);
        let { activeKey } = tagsItem;
        let lastIndex: any;
        tagsItem.panes.forEach((pane: any, i: any) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const panes = tagsItem.panes.filter((pane: any) => pane.key !== targetKey);
        if (panes.length && activeKey === targetKey) {
            if (lastIndex >= 0) {
                activeKey = panes[lastIndex].key;
            } else {
                activeKey = panes[0].key;
            }
        }
        setTagsItem({ panes, activeKey });
        setPathname(activeKey as string);
        props.history.push({
            pathname: activeKey
        })
    }

    /**
     * 路由切换是的变化
     * @param route
     */
    const onRouteChange = (route: any) => {
        route?.path && setPathname(route.path);
        setTimeout(() => {
            document.title = menusName;
        })
    }

    useEffect(() => {
        const aside = document.querySelector('.pc-layout-basic')?.querySelector('aside');
        if (aside) {
            const changeCollapsed = (type: boolean) => {
                const MenuFoldOutlinedDom: any = document.querySelector('#MenuFoldOutlined');
                const MenuUnfoldOutlinedDom: any = document.querySelector('#MenuUnfoldOutlined');
                if (MenuFoldOutlinedDom && MenuUnfoldOutlinedDom) {
                    if (type) {
                        MenuFoldOutlinedDom.style.display = 'none';
                        MenuUnfoldOutlinedDom.style.display = '';
                    } else {
                        MenuFoldOutlinedDom.style.display = '';
                        MenuUnfoldOutlinedDom.style.display = 'none';
                    }
                }
            }
            const observer = new MutationObserver(debounce(() => {
                changeCollapsed(aside.classList.contains('ant-layout-sider-collapsed'))
            }));
            observer.observe(aside, { subtree: false, attributes: true });
            return () => {
                observer.disconnect();
            }
        }
    }, []);

    /**
     * 树根账号oem定制
     */
    const isShuGen = getCurrentUserFromStorage().username === '13922278758';

    return (
        <React.Fragment>
            <ProLayout
                {...proSettings}
                location={{
                    pathname
                }}
                logo={isShuGen ? shu_gen_logo : menusLogo}
                title={isShuGen ? '' : menusName}
                pageTitleRender={() => {
                    return menusName;
                }}
                breakpoint={false}
                defaultCollapsed={true}
                route={{ routes: router, path: "/" }}
                className={'pc-layout-basic'}
                // collapsed={collapsed}
                // onCollapse={setCollapsed}
                postMenuData={(menuData: any) => {
                    return menuData;
                }}
                headerContentRender={() => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                onClick={() => {
                                    const bth: any = document.querySelector('.pc-layout-basic')?.querySelector('.ant-pro-sider-links')?.querySelector('.ant-menu-title-content');
                                    if (bth) {
                                        bth.click();
                                    }
                                }}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    marginRight: '20px',
                                }}>
                                <span>
                                    <MenuUnfoldOutlined id={`MenuUnfoldOutlined`}/>
                                    <MenuFoldOutlined id={`MenuFoldOutlined`} style={{ display: 'none' }}/>
                                </span>
                            </div>
                            <ProBreadcrumb/>
                        </div>

                    );
                }}
                breadcrumbRender={(routers = []) => [
                    ...routers,
                ]}
                menuDataRender={(menuList: any) => {
                    // menuData 的 render 方法，用来自定义 menuData
                    return menuList
                }}

                menuItemRender={({ path, icon, name }: MenuDataItem, defaultDom) => {
                    // 渲染菜单项
                    return (
                        <>
                            {
                                name === 'OTA升级详情' ? null :
                                    <div className={`layoutMenuItem`}>
                                        <Link to={path as string}>
                                            {createIcon(icon as string)}
                                            <span className={`ant-pro-menu-item-title`}>{name}</span>
                                        </Link>
                                    </div>
                            }
                        </>
                    );
                }}
                rightContentRender={() => (<RightLayout/>)}
            >
                <div className='home-tabs'>
                    <Tabs hideAdd
                          type="editable-card"
                          onChange={onChangeHandler}
                          onEdit={onEditHandler}
                          activeKey={tagsItem.activeKey}
                    >
                        {tagsItem.panes.map((pane: any) => (
                            <TabPane tab={pane.title} key={pane.key} closable={pane.closable}></TabPane>
                        ))}
                    </Tabs>
                </div>
                <ConfigProvider
                    // modfiy by ligm12 for keep-alive tooltip at 20210220
                    getPopupContainer={(triggerNode: any) => {
                        const hoc: any = document.querySelector('[attr-hoc="hoc-main"]');
                        return hoc?.firstChild?.firstChild;
                    }}>
                    <RouteWithModuleRoutes uninstallKeepAliveKeys={uninstallKeepAliveKeys} keepAlive={'auto'}
                                           loading={true}
                                           onRouteChange={onRouteChange} routers={router}/>
                </ConfigProvider>
            </ProLayout>
        </React.Fragment>
    );
};
export default withRouter(BasicLayout);
