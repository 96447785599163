/**********************************************************************
 *
 * @模块名称: HttpClient
 *
 * @模块用途: HttpClient
 *
 * @date: 2021/7/26 8:25
 *
 * @版权所有: pgli
 *
 **********************************************************************/
import intercept from "@httpClient/intercept";
import { register, createFetch } from '@gaopeng123/fetch';
import { message } from "antd";
import { initCreateFetch } from "@gaopeng123/hooks";

export { get, post, put, del, patch, createFetch } from "@gaopeng123/fetch";

initCreateFetch(createFetch);

export const checkStatue = (res: any) => res.code === 200 || res.status === 200;

export const checkMsg = (res: any) => res.msg || res.message || res.result;

export const checkStatueAndAction = (res: any, success: (res: any) => any, fail: (res: any) => any) => {
    if (checkStatue(res)) {
        return success(res);
    }
    message.destroy();
    message.warn(checkMsg(res));
    return fail(res);
};

type OnFn = () => void;
export interface HandleResOpt { onSuccess?: OnFn, onFail?: OnFn }
export const handleRes = (res: any, opt?: HandleResOpt) => {
    message.destroy();
    if (checkStatue(res)) {
        message.success(checkMsg(res));
        if (opt?.onSuccess) {
            opt.onSuccess();
        }
    } else {
        message.warn(checkMsg(res))
        if (opt?.onFail) {
            opt.onFail();
        }
    }
}

export const checkUrl = (url: string) => {
    return url?.startsWith(process.env.REACT_APP_BASEURL as string) ? url : process.env.REACT_APP_BASEURL + url;
}

export const unregisterFetch = register(intercept);
