import React, {useEffect, useRef, useState} from 'react';
import {Badge, Drawer, notification} from 'antd';
import styles from './index.module.less';
import WebSocketCom from "@components/WebSocket";
import {post} from "@/httpClient";
import Icon from '@/components/Icon';
import {formatTimestamp, randomInt, uuid} from "@gaopeng123/utils";
import NoticeList from "@/layouts/HeaderNotice/NoticeList";
import AlertItem from "@components/AlertInfo/AlertItem";
import SoftTableDetail from "@pages/alarmMangage/components/SoftTableDetail";
import {assembleAlertDetailStore} from "@share/assembleAlertDetail";
import {useDebounce} from "@gaopeng123/hooks";
import {usePoller} from "@gaopeng123/hooks";
import {Howl} from 'howler'

const sound = new Howl({ src: ['/audios/alarm.wav'] })
const soundAlert = async (volume: number) => {
    sound.volume(volume / 100)
    sound.play()
}

const socketSent = async () => {
    post('/iot-xingtu20-service-tenant/kafka/send?topic=test_nebula_terminal_fence_topic', {
        body: {
            "timestamp": formatTimestamp(Date.now() - 99999999),
            "terminalId": "SAB12220100002",//设备序列号
            "msgType": 2,//1通知 2报警
            "terminalType": 2,// 1  aibox 2 智能作业终端
            "fenceType": 3,// 0 路线 1 圆形 2 方形 3 多边形
            "fenceName": "yt测试",//围栏名称
            "alarmCondition": randomInt(0, 3),//报警原因 0路线偏离 1禁止驶入2禁止驶出3禁止超速
            "location": "北京昌平区北清路8号三一产业园",//地点 已获取，无需再次获取
            "position": "116.1,40.2",//经纬度 注意 这里的坐标已经转换成高德坐标系
            "speed": 20,//km/h 速度
            "fenceId": 11,//对应的系统内电子围栏规则的id
            "vehicleName": "京A22223" //车辆名称/车牌
        }
    });
}

const HeaderNotice: React.FC<any> = (props: any) => {
    const {alarmConfigState} = props
    const {ready, popupEnable, soundEnable, volume} = alarmConfigState
    /**
     * 显示告警表
     */
    const [isModalVisible, setIsModalVisible] = useState(false);
    /**
     * 告警数量
     */
    const [count, setCount] = useState<number>(0);
    /**
     * 传递接口所需要的参数
     */
    const [store, setStore] = useState<any>({});
    /**
     * 消息的key
     */
    const [notificationKey, setNotificationKey] = useState<string>('');
    const [lastNotificationKey, setLastNotificationKey] = useState<string>('');
    const actionRef = useRef<any>();
    const handleRequest = (data: any) => {
        setCount(data.total || 0);
    }

    /**
     * 告警处理
     */
    const dealWithAlert = (alertData: any) => {
        setStore(assembleAlertDetailStore(alertData));
    }

    useEffect(() => {
        /**
         * 只保留最新的一条 todo 盛伟于2022-3-7 11:35分会议决定
         */
        if (lastNotificationKey) notification.close(lastNotificationKey);
        setLastNotificationKey(notificationKey);
    }, [notificationKey]);

    /**
     * socket过来后的处理
     * @param socketData
     */
    const popupAlarm = (alertData: any) => {
        /**
         * 弹出全局的告警消息
         */
        const notificationKey: string = uuid();
        notification.open({
            className: styles.alertNotification,
            message: '',
            key: notificationKey,
            description: <AlertItem {...alertData} onClick={() => {
                notification.close(notificationKey);
                dealWithAlert(alertData);
            }}/>,
            onClick: () => {
                console.log('Notification Clicked!');
            },
            top: 60,
            duration: 10 // 10秒关闭
        });
        setNotificationKey(notificationKey);
    }
    
    const onEvent = useDebounce((socketData: any) => {
        const alertData = socketData[socketData?.length - 1];
        if (alertData) {
            // console.log('onevent', alertData)
            popupEnable && popupAlarm(alertData)
            soundEnable && !!volume && soundAlert(volume)
        }
        // @ts-ignore
        actionRef?.current?.reload();
    }, 200, {});

    // 异步轮询 https://rs2flu7c17.work.sany.com.cn/docs/dock56dZVxvXtzIQmHEzqTfwzRe#
    const [asyncTime, startAsyncPoller, stopAsyncPoller] = usePoller({
        delay: 30000,
        callBack: async (params) => {
            // 满足条件时 停止计时器 return即可
            // @ts-ignore
            actionRef?.current?.reload();
        }
    });

    return (
        <div style={{padding: '0px 16px', display: 'flex', alignItems: 'center'}}>
            {ready && <WebSocketCom url={`/socket/websocket/0`} onEvent={onEvent}/>}
            {/*<div onClick={() => {*/}
            {/*    // @ts-ignore*/}
            {/*    onEvent([{}]);*/}
            {/*    for (let i = 0; i < 100; i++) {*/}
            {/*        setTimeout(() => {*/}
            {/*            // @ts-ignore*/}
            {/*            onEvent([{}]);*/}
            {/*        });*/}
            {/*    }*/}
            {/*}}>发送*/}
            {/*</div>*/}
            <div style={{cursor: "pointer", paddingTop: 8}} onClick={() => {
                setIsModalVisible(true);
            }}>
                <Badge count={count} offset={[4, 8]}>
                    <Icon style={{fontSize: 28}} type={`icon-baojingguanli-xuanzhong`}/>
                </Badge>
            </div>
            <Drawer
                title={<Badge offset={[24, 7]} count={count}><span className={styles.title}>报警信息</span></Badge>}
                className={`${styles.alertDrawer} custom-drawer`}
                placement="right"
                closable={true}
                width={1000}
                onClose={() => {
                    setIsModalVisible(false);
                }}
                visible={isModalVisible}
                getContainer={false}
                contentWrapperStyle={{paddingTop: 50}}
                bodyStyle={{padding: 0}}
            >
                <NoticeList actionRef={actionRef} handleRequest={handleRequest}/>
            </Drawer>
            <SoftTableDetail state={store} dispatch={() => {
                // @ts-ignore
                actionRef.current.reload();
            }}/>
        </div>
    )
}

export default HeaderNotice;
