/**********************************************************************
 *
 * @模块名称: api
 *
 * @模块作用:
 *
 * @创建人: ligm12
 *
 * @date: 2022/6/6 14:46
 *
 * @版权所有: SANY ⋅ 重工 ⋅ 智能研究总院
 *
 **********************************************************************/
 import {checkMsg, checkStatue, put} from "@/httpClient";
 import {message} from "antd";
 
 export const changePassWordApi = async (params: any) => {
     const res = await put(`/iot-common-auth-tenant/api/nebula/auth/admin/v1/user/password/update`, {body: params});
     if (checkStatue(res)) {
         return res;
     }
     message.warn(checkMsg(res));
 }
 