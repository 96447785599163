/**********************************************************************
 *
 * @模块名称: api
 *
 * @模块用途: api 定义
 *
 * @创建人: diaojw
 *
 * @date: 2021-11-17 17:19:45
 *
 **********************************************************************/
import { checkMsg, checkStatue, checkStatueAndAction, del, get, post } from "@/httpClient";
import { AddSoftTableProps, EditSoftTableProps, TableListItem } from "./typing";
import { message } from "antd";
import { download } from "@gaopeng123/utils";

export const SoftTableList = async (params: any) => {
    const tableListDataSource: TableListItem[] = [];
    return tableListDataSource;
};

/**
 * 新增接口
 */
export const addSoftTable = async (params: AddSoftTableProps) => {
    return await post('./json/OEM.json', { body: params });
};

/**
 * 编辑接口
 */
export const editSoftTable = async (params: EditSoftTableProps) => {
    return await post('./json/OEM.json', { body: params });
};

/**
 * 删除接口
 */
export const delSoftTable = async (ids: any) => {
    return await del('/iot-common-device-tenant/api/nebula/device/v1', { body: ids });
};
/**
 * 终端类型号
 */
export const getTerminalType = async (deviceTypeName: any) => {
    return await get(`/iot-common-device-tenant/api/nebula/device/aiot/deviceTypeno/v1?deviceTypeName=${deviceTypeName}`)
};
/**
 * 终端新增
 */
export const getTerminalAdd = async (data: any) => {
    return await post(`/iot-common-device-tenant/api/nebula/device/aiot/addXtTerminal/v1`, { body: data })
};
/**
 * 终端
 */
export const getDeviceAdd = async (data: any) => {
    return await post(`/iot-common-device-tenant/api/nebula/device/v1`, { body: data })
};
/**
 * 终端查询
 */
export const getQueryBindDevice = async (data: any) => {
    return await post(`/iot-common-device-tenant/api/nebula/device/bind/list/v1`, { body: data })
};
/**
 * 查询设备
 */
export const getQueryDeviceList = async (data: any, pageNum: any, pageSize: any) => {
    return await post(`/iot-common-device-tenant/api/nebula/device/devices/v1?pageNum=${pageNum}&pageSize=${pageSize}`, { body: data })
};
/**
 * 终端修改
 */
export const getBindDeviceUpDate = async (data: any) => {
    return await post(`/iot-common-device-tenant/api/nebula/device/aiot/updateSensorTerminal/v1`, { body: data })
};
/**
 * 添加设备型号
 */
export const getDeviceXtTypeNoAdd = async (data: any) => {
    return await post(`/iot-common-device-tenant/api/nebula/device/aiot/addXtDeviceTypeNo/v1`, { body: data })
};
/**
 * 查询智能作业终端指令--参数设置
 */
export const getQueryParamsSetting = async (params: any) => {
    return await get(`/iot-common-device-tenant/api/nebula/device/aiot/cmd/search/smartJob/v1`, { params })
};
/**
 * 查询智能作业终端指令--参数设置---修改
 */
export const getParamsSettingUpdate = async (data: any) => {
    return await post(`/iot-common-device-tenant/api/nebula/device/aiot/cmd/update/smartJob/v1`, { body: data })
};
/**
 * 标定模式---开始采集   type: 1运行；2怠速；3静止   carId:车辆类型_车辆编号
 */
export const getCalibrationSample = async (data: any) => {
    return await post(`/iot-xingtu20-service-tenant/calibration/sample`, { params: data })
};
/**
 * 标定模式---运行状态配置计算  carId:车辆类型_车辆编号
 */
export const getCalibrationTrain = async (data: any) => {
    return await post(`/iot-xingtu20-service-tenant/calibration/train`, { params: data })
};
/**
 * 标定模式---运行状态配置计算更新  carId:车辆类型_车辆编号
 */
export const getCalibrationTrainUpDate = async (data: any) => {
    return await post(`/iot-xingtu20-service-tenant/calibration/config`, { body: data })
};
// 初始获取值
export const getCalibrationConfig = async (params: any) => {
    return await get(`/iot-xingtu20-service-tenant/calibration/config`, { params })
};
// 数据下载
export const templateDataExport = async (data: any) => {
    // return await  get('/iot-xingtu20-service-tenant/calibration/sample/download',{
    return await get('/iot-xingtu20-service-tenant/calibration/sample/list', {
        params: data,
        // responseType: 'blob',
        // noModification: true
    })
}
/**
 * 终端上传文件接口---校验
 */
export const reqPdaValidateUpload = async (data: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/import/xtTerminalValidate/v1', {
        body: data,
        headers: {}
    });
};
/**
 * 终端上传文件接口---
 */
export const reqPdaUpload = async (data: any) => {
    return await post('/iot-common-device-tenant/api/nebula/device/aiot/import/xtTerminal/v1', {
        body: data,
        headers: {}
    });
};

/**
 * 重置蜂鸣器
 * @param data
 */
export const cmdSmartJob = async (data: any) => {
    const res = await post('/iot-common-device-tenant/api/nebula/device/aiot/cmd/update/smartJob/v2', {
        body: data,
    });
    if (checkStatue(res)) {
        message.success(res.data || checkMsg(res));
    } else {
        message.warn(res.data || checkMsg(res));
    }
};
/**
 * 重启智能作业终端指令
 * @param data
 */
export const cmdResetSmartJob = async (data: any) => {
    const res = await post('/iot-common-device-tenant/api/nebula/device/aiot/cmd/restart/smartJob/v1', {
        body: data,
    });
    if (checkStatue(res)) {
        message.success(res.data || checkMsg(res));
    } else {
        message.warn(res.data || checkMsg(res));
    }
};

/**
 * 文件导出
 * @param data
 */
export const exportTerminal = async (data: any) => {
    const res = await post('/iot-xingtu20-service-tenant/trajectory/export/devices/terminal/list', {
        body: data,
        noModification: true,
    });
    if (res.headers.get('content-type') === 'application/json') {
        res.clone().json().then((resData: any) => {
            message.warn(resData.data || checkMsg(resData));
        })
    } else {
        res?.clone()?.blob()?.then((blob: Blob) => {
            message.success(`开始下载...`);
            console.info('content-disposition', res.headers.get('content-disposition'))
            const fileName = res.headers.get('content-disposition')?.replace('attachment;filename=', '') || `${'车辆管理'}.xlsx`;
            download({ blob: blob, fileName: fileName })
        });
    }
}


/**
 * 批量移动终端设备
 */
type UpdateTerminalAreaBatchParams = {
    devices: Array<{ areaId: number, id: number }>,
    areaId: number
}
export const updateTerminalAreaBatch = async (params: UpdateTerminalAreaBatchParams) => {
    return post('/iot-common-device-tenant/api/nebula/device/aiot/updateTerminalAreaBatch/v1', { body: params });
}
/**
 * 设置油位参数
 * @param params
 */
type TerminalParams = {
    id: number;
    readFreqWork?: number;
    readFreqStatic?: number;
    readFreqAddOil?: number;
}
export const setTerminalParams = async (params: TerminalParams) => {
    return await post('/iot-common-device-tenant/api/nebula/device/smartFuelCapCmd/readFreq/setParams/v1', { body: params });
};

export const getTerminalParams = async (params: TerminalParams) => {
    const res = await post('/iot-common-device-tenant/api/nebula/device/smartFuelCapCmd/readFreq/params/v1', { body: params });
    return checkStatueAndAction(res, () => {
        return res;
    }, () => {
        return false
    })
};

/**
 * 功能优化参数配置  id和terminalId 传一个就行
 */
interface OptimizeParams {
    id?: number; // 终端id
    terminalId?: string; // 终端号
    powerOptimizationSwitch?: 0 | 1; // 功耗优化开关值 0关，1开
    GPSCamDuration?: number;  // GPS模块搜星时长
    GPSONSpeedThreshold?: number;  // GPS常开速度阈值异常
}

export const getTerminalOptimizeParams = async (params: OptimizeParams) => {
    const res = await post('/iot-common-device-tenant/api/nebula/device/smartCmd/consumption/get', { body: params });
    return checkStatueAndAction(res, () => {
        return res;
    }, () => {
        return false
    })
};

export const setTerminalOptimizeParams = async (params: OptimizeParams) => {
    return await post('/iot-common-device-tenant/api/nebula/device/smartCmd/consumption/set', { body: params });
};