/**
 *@模块名称：WebSocket
 *
 *@创建人：ligaoming
 *
 *@作用：消息推送
 *
 *@date 2020/11/3
 *
 *@版权所有：
 */
import React, {useEffect} from 'react';
import {isObject} from "@gaopeng123/utils";
import WebSocketManager from "@components/WebSocket/WebSocketManager";

type WebSocketComProps = {
    url: string;
    onEvent?: (v: any) => void;
    message?: any;
    isDestroy?: boolean;
}
const WebSocketCom = (props: WebSocketComProps) => {
    const {url, onEvent, message, isDestroy} = props;
    useEffect(() => {
        if (url) {
            WebSocketManager.create(url, {
                onMessage: (data) => {
                    let formatData = data;
                    if (isObject(formatData)) formatData = [formatData];
                    onEvent && onEvent(formatData);
                },
                onOpen: () => {

                }
            });
        }
        return () => {
            if (isDestroy) {
                WebSocketManager.destroy(url);
            } else {
                WebSocketManager.close(url);
            }
        }
        /**
         * 断线重连
         */
    }, [url]);

    /**
     * 接收消息 发送出去
     */
    useEffect(() => {
        if (message) {
            WebSocketManager.sendMessage(url, JSON.stringify(message));
        }
    }, [message]);

    return (
        <React.Fragment></React.Fragment>
    )
};

export default WebSocketCom;