/**
 * @函数名称：RightLayout
 * @param
 * @作用：右边的布局
 * @return：obj
 * @date 2020/9/7
 */

import React, {useReducer, Reducer} from 'react';
import {Settings as ProSettings} from '@ant-design/pro-layout';
import styles from './index.module.less';
import UserDropdown from '../HeaderDropdown/user';
import HeaderNotice from '../HeaderNotice';
import ScreenEntrance from "@/layouts/RightLayout/components/ScreenEntrance";
import AlarmConfig from './components/AlarmConfig';
import { reducer, getInitialState } from '@/store/AlarmConfig';

export interface GlobalHeaderRightProps extends Partial<any>, Partial<ProSettings> {
    theme?: ProSettings['navTheme'] | 'realDark';
}

const ENVTagColor: any = {
    dev: 'orange',
    test: 'green',
    pre: '#87d068',
};

const RightLayout: React.SFC<GlobalHeaderRightProps> = (props) => {
    const [alarmConfigState, alarmConfigDispatch] = useReducer<Reducer<any, any>>(reducer, getInitialState())
    const {theme, layout, currentUser, fetchingNotices, onNoticeVisibleChange} = props;
    let className = styles.right;

    if (theme === 'dark' && layout === 'top') {
        className = `${styles.right}  ${styles.dark}`;
    }

    const REACT_APP_ENV: any = process.env.REACT_APP_ENV || 'pre';

    return (
        <div className={className}>
            {/*<HeaderSearchInterface/>*/}
            <ScreenEntrance/>
            <HeaderNotice alarmConfigState={alarmConfigState} alarmConfigDispatch ={alarmConfigDispatch}/>
            <AlarmConfig alarmConfigState={alarmConfigState} alarmConfigDispatch ={alarmConfigDispatch}/>
            <UserDropdown/>
        </div>
    );
};

export default RightLayout;
